import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import Axios from 'axios'
import Modal from 'simple-react-modal'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Config from '../../../../config'
import LstUsuario from './lstUsuario'
import { criptografar, descriptografar } from '../../../../lib/utils2'

function getCookie(cName) {
  const name = cName + "=";
  const cDecoded = decodeURIComponent(document.cookie); //to be careful
  const cArr = cDecoded.split('; ');
  let res;
  cArr.forEach(val => {
    if (val.indexOf(name) === 0) res = val.substring(name.length);
  })
  return res
}

export default class usuario extends Component {

  state = {
    id: 0,
    nome: '',
    usuario: '',
    senha: '',
    showPwd: false,
    idCliente: 0,
    perfil: 0,
    lstClientes: [],
    lstMunicipio: [],
    lstMunicipioSelecionado: [],
    showModal: false
  }
  salvar = this.salvar.bind(this)
  confirmaExcluir = this.confirmaExcluir.bind(this)

  async salvar() {
    let _acao = this.state.id == 0 ? "/php/sistema/usuario_insert.php" : '/php/sistema/usuario_update.php'
    await Axios.post(`${Config.dominio}${_acao}`,
      JSON.stringify({
        id: this.state.id,
        nome: encodeURI(this.state.nome),
        usuario: this.state.usuario,
        senha: this.state.senha,
        cliente: this.state.idCliente,
        lstMunicipio: this.state.lstMunicipioSelecionado.toString(),
        local: sessionStorage.getItem('local'),
        chave : descriptografar(getCookie('chave')),
      })
    )
      .then(async function (response) {
        if (response.data === 0) {
          toast.error("Usuário já existe. Por favor, informe um usuário diferente.", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })

        } else {
          toast.success('Usuário cadastrado com sucesso!', {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            onClose: ReactDOM.render(<LstUsuario />, document.getElementById('Content'))
          })
        }
      })
      .catch(erro =>
        toast.error("Falha ao cadastrar  usuário. Por favor, tente novamente.", {
          position: "top-right",
          autoClose: 6000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }))
  }

  cancelaExcluir() {
    ReactDOM.render(<LstUsuario />, document.getElementById('Content'))
  }

  async confirmaExcluir() {
    await Axios.post(
      `${Config.dominio}/php/sistema/usuario_delete.php`,
      JSON.stringify(
        {
          id: this.state.id,
          chave : descriptografar(getCookie('chave'))
        }
      )
    )
      .then(async function (response) {
        toast.success('Usuário removido!', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          onClose: ReactDOM.render(<LstUsuario />, document.getElementById('Content'))
        })
      })
      .catch(erro => console.log(erro))
  }

  async listarMunicipio(id = this.state.idCliente) {
    await Axios.post(`${Config.dominio}/php/sistema/empreiteiraMunicipio_list.php`,
      JSON.stringify({
        idEmpresa: id,
        tipo: 0,
                        local: sessionStorage.getItem('local'),
                        chaveMunicipio: descriptografar(getCookie('chave'))
      })
    )
      .then(async (response) => {
        let lista = []
        response.data.map(item => lista.push({ id: item.idmunicipio, nome: item.nomemunicipio }))
        this.setState({ lstMunicipio: [...new Set(lista)] })
      })
      .catch(erro => console.log(erro))

  }

  selecionarCliente(id) {
    this.setState({ idCliente: id })
    this.listarMunicipio(id)

  }

  async usuarioMunicipio() {
    await Axios.post(`${Config.dominio}/php/sistema/usuarioMunicipio_list.php`,
      JSON.stringify({ idUsuario: this.state.id, tipo: 0,
                        local: sessionStorage.getItem('local'),
                        chaveMunicipio: descriptografar(getCookie('chave')) })
    )
      .then(async response => {
        let lst = this.state.lstMunicipioSelecionado
        response.data.map(it => lst.push(it.idMunicipio))
        this.setState({ lstMunicipioSelecionado: lst })
      })
      .catch(erro => console.log(erro))

  }

  async componentWillMount() {
    await Axios.post(`${Config.dominio}/php/sistema/cliente_list.php`, JSON.stringify({
                        tipo: 0,
                        local: sessionStorage.getItem('local'),
                        chaveMunicipio: descriptografar(getCookie('chave'))
                     }))
      .then(async (response) => {
        this.setState({ lstClientes: response.data.sort((a, b) => a.nome > b.nome ? 1 : -1) })
      })
      .catch(erro => console.log(erro))
  }

  selecionados(municipio) {
    let lista = this.state.lstMunicipioSelecionado
    if (lista.includes(municipio)) {
      lista = lista.filter(item => item != municipio)
    } else {
      lista.push(municipio)
    }
    this.setState({ lstMunicipioSelecionado: [...new Set(lista)] })
  }

  async componentDidMount() {
    console.log(this.props)
    if (this.props.action == "new")
      this.setState({ idCliente: this.props.cliente, nome: '', senha: '', usuario: '' })

    if (this.props.action == "edit") {
      await Axios.post(`${Config.dominio}/php/sistema/usuario_list.php`,
        JSON.stringify({ id: this.props.id, tipo: 0,
                        local: sessionStorage.getItem('local'),
                        chaveMunicipio: descriptografar(getCookie('chave')) })
      )
        .then(async function (response) {
          this.setState({
            id: this.props.id,
            idCliente: response.data[0].idCliente,
            nome: response.data[0].nome,
            usuario: response.data[0].username,
            senha: response.data[0].password
          })
        }.bind(this))
        .catch(erro => console.log(erro))

    }

    if (this.props.action == "delete") {
      await Axios.post(`${Config.dominio}/php/sistema/usuario_list.php`,
        JSON.stringify({ id: this.props.id, tipo: 0,
                        local: sessionStorage.getItem('local'),
                        chaveMunicipio: descriptografar(getCookie('chave')) })
      )
        .then(async response => {
          this.setState({
            nome: response.data[0].nome,
            id: this.props.id,
            idCliente: response.data[0].idCliente,
            usuario: response.data[0].username,
            senha: response.data[0].password
          })
        })
        .catch(erro => console.log(erro))
      this.setState({ showModal: true })
    }

    this.listarMunicipio()
    this.usuarioMunicipio()
  }

  render() {
    return (
      <>
        <ToastContainer />
        <Modal
          show={this.state.showModal}
          containerStyle={{ background: 'Transparent' }}
          transitionSpeed={400}
        >
          <div className="box box-danger">
            <div className="box-body">
              <div className="row">
                <div className="col-xs-12">
                  <h3>Confirma excluir esse usuário?</h3>
                </div>
              </div>
            </div>
            <div className="box-footer">
              <center>
                <button
                  onClick={this.confirmaExcluir}
                  className="btn btn-danger btn-flat"
                >
                  Excluir
                </button>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <button
                  onClick={this.cancelaExcluir}
                  className="btn btn-warning btn-flat"
                >
                  Cancelar
                </button>
              </center>
            </div>
          </div>
        </Modal>

        <section className="content-header">
          <h1>
            Usuários
            <small>Cadastro</small>
          </h1>
          <ol className="breadcrumb">
            <li>
              <button
                className="btn btn-danger btn-flat"
                onClick={() => ReactDOM.render(<LstUsuario />, document.getElementById('Content'))}
              >
                Voltar
              </button>&nbsp;&nbsp;
              <button className="btn btn-primary" onClick={this.salvar} >Salvar</button>
            </li>
          </ol>
        </section>
        <section className="content">
          <div className="col-md-4">
            <div className="box">
              <div className="box box-success">
                <div className="box-header with-border">
                  <h3 className="box-title">Descrição</h3>
                  <div className="box-body">
                    <div className="row">
                      <div className="col-xs-12">
                        <div className="form-group">
                          <label htmlFor="nome">Nome</label>
                          <input
                            type="text"
                            value={`${this.state.nome}`}
                            className="form-control"
                            id="nome"
                            placeholder=""
                            onChange={event => this.setState({ nome: event.target.value })}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xs-12">
                        <div className="form-group">
                          <label htmlFor="cliente">Cliente</label>
                          <select
                            className="form-control select2"
                            value={this.state.idCliente}
                            disabled={this.state.idCliente != ''}
                            onChange={e => this.selecionarCliente(e.target.value)}
                          >
                            <option key={-1} value=''>Escolha um Cliente</option>
                            {this.state.lstClientes &&
                              this.state.lstClientes.map((item, index) => <option key={index} value={item.id}>{item.nome}</option>)
                            }
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="box-footer">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-8">
            <div className="box">
              <div className="box box-success">
                <div className="box-header with-border">
                  <h3 className="box-title">Acesso</h3>
                  <div className="box-body">
                    <div className="row">
                      <div className="col-xs-6">
                        <div className="form-group">
                          <label htmlFor="usuario">Usuário</label>
                          <input
                            type="text"
                            value={this.state.usuario}
                            className="form-control"
                            placeholder=""
                            onChange={event => this.setState({ usuario: event.target.value })}
                          />
                        </div>
                      </div>
                      <div className="col-xs-6">
                        <div className="form-group">
                          <label htmlFor="senha">Senha</label>

                          <div className="input-group input-group-xs">
                            <input className="form-control " type={this.state.showPwd ? "text" : "password"} minLength="5" value={this.state.senha} onChange={event => this.setState({ senha: event.target.value })} />
                            <span className="input-group-btn">
                              <button type="button" className="btn btn-info btn-flat" onClick={() => this.setState({ showPwd: !this.state.showPwd })}><span className={`fa ${this.state.showPwd ? 'fa-eye' : 'fa-eye-slash'}`} /></button>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="box-footer">
                  </div>
                </div>
              </div>
              <div className="box box-info">
                <div className="box-header with-border">
                  <h3 className="box-title">Municípios</h3>
                  <div className="box-body">
                    <div className="row">
                      <div className="col-xs-12">
                        <div className="form-group">
                          <label htmlFor="usuario">Selecione os municípios de trabalho do usuário</label>
                          <select
                            multiple
                            className="form-control"
                            style={{ height: '150px' }}
                            value={this.state.lstMunicipioSelecionado}
                            onChange={event => this.selecionados(event.target.value)}
                          >
                            {
                              this.state.lstMunicipio &&
                              this.state.lstMunicipio.sort((a, b) => a.nome < b.nome).map((item, index) => <option key={index} value={item.id}>{item.nome}</option>)
                            }
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="box-footer">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    )
  }
}