import React, { Component } from 'react'
import Header from './Header/Header'
import Menu from './Menu/Menu'
import Footer from './Footer/Footer'
import Content from './mainActions'
import './style.css'


export default class Actions extends Component {

  render() {
    return (
      <div className="actionBackground">
        <Header /> 
        <Menu />
        <Content />
        <Footer />
      </div> 
    )
  }
}