import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import Modal from 'simple-react-modal'
import ReactTooltip from 'react-tooltip'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import { usuario } from '../../../../lib/utils2'
import { AxiosPost } from '../../../../lib/utils3'
import Config from '../../../../config'
import Axios from 'axios'
import LstOrdensDeServico from "../../../actionParts/ordemDeServico/lstOrdensDeServico"

const animatedComponents = makeAnimated()

const scrollList = {
  overflowY: 'scroll',
  height: '150px'
}

export default class detalheOS extends Component {

  state = {
    result: null,
    abertura: null,
    autorizacao: null,
    distribuicao: null,
    baixa: null,
    materialInserido: null,
    editIncluirMateriais: false,
    materialRemovido: null,
    editRemoverMateriais: false,
    lstMaterial: [],
    materialItem: null,
    materialQuantidade: 1,
    lstMateriaisRef: [],
    lstMaterialIncluido: [],
    lstMaterialRemovido: [],
    addOrUpdate: false,
    load: false,
    lstMat: []
  }

  async materiais() {
    await AxiosPost(`${Config.dominio}/php/materiais_list.php`,
      JSON.stringify({ a: 1 })
    )
      .then(async function (response) {
        let _lista = []
        response.data.filter(item => item.dadosPonto == 1)
          .map(item => _lista.push({
            value: item.id,
            label: item.codigo + " " + item.descricao
          })
          )
        this.setState({
          lstMateriaisRef: response.data,
          lstMateriais: _lista
        })
      }.bind(this))
      .catch(erro => console.log(erro))
  }

  async abertura(protocolo) {
    await AxiosPost(`${Config.dominio}/php/os/abertura_list.php`, JSON.stringify({ protocolo: protocolo }))
      .then(response => this.setState({ abertura: response.data[0] }))
      .catch(erro => console.log(erro))
  }

  async autorizacao(protocolo) {
    await AxiosPost(`${Config.dominio}/php/os/autorizacao_list.php`, JSON.stringify({ protocolo: protocolo }))
      .then(response => this.setState({ autorizacao: response.data[0] }))
      .catch(erro => console.log(erro))
  }

  async distribuicao(protocolo) {
    await AxiosPost(`${Config.dominio}/php/os/distribuicao_list.php`, JSON.stringify({ protocolo: protocolo }))
      .then(response => this.setState({ distribuicao: response.data }))
      .catch(erro => console.log(erro))
  }

  async baixa(protocolo) {
    await AxiosPost(`${Config.dominio}/php/os/baixa_list.php`, JSON.stringify({ protocolo: protocolo }))
      .then(response => this.setState({ baixa: response.data[0] }))
      .catch(erro => console.log(erro))
  }

  incluirMateriaisAdicionarItens() {
    if (this.state.materialItem) {
      let _lista = this.state.lstMaterialIncluido
      _lista.unshift({
        material: this.state.lstMateriaisRef.find(item => item.id == this.state.materialItem.value),
        quantidade: this.state.materialQuantidade,
        novo: true,
        remover: false
      })
      this.setState({ lstMaterialIncluido: _lista })
    }
    this.setState({ materialItem: null, materialQuantidade: 1 })
  }

  async incluirMateriaisRemoverItens(index, item) {
    let _lista = this.state.lstMaterialIncluido
    let removerMaterial = _lista.filter((item, ind) => index != ind)
    if (window.confirm(`Deseja transferir o material: ${item.material} para o estoque primário?`)) {
      await AxiosPost(`${Config.dominio}/php/estoque/estoque_editar_material.php`,
        JSON.stringify({
          protocolo: this.props.protocolo,
          material: item.idmaterial,
          quantidade: item.quantidade,
          idestoque: 1,
          movimentacao: 'E'
        })
      )
        .then(async response => {
          this.setState({ lstMaterialIncluido: removerMaterial })
        })
        .catch(erro => console.log(erro))


      await AxiosPost(`${Config.dominio}/php/estoque/estoque_delete.php`,
        JSON.stringify({
          id: item.id
        })
      )
        .then(async response => {
          this.setState({ lstMaterialIncluido: removerMaterial })
        })
        .catch(erro => console.log(erro))
    }
  }

  removerMateriaisAdicionarItens() {
    if (this.state.materialItem) {
      let _lista = this.state.lstMaterialRemovido
      _lista.unshift({
        material: this.state.lstMateriaisRef.find(item => item.id == this.state.materialItem.value),
        quantidade: this.state.materialQuantidade,
        novo: true,
        remover: false
      })
      this.setState({ lstMaterialRemovido: _lista })
    }
    this.setState({ materialItem: null, materialQuantidade: 1 })
  }

  async removerMateriaisRemoverItens(index, item) {
    let _lista = this.state.lstMaterialRemovido
    let removerMaterial = _lista.filter((item, ind) => index != ind)
    if (window.confirm(`Deseja transferir o material: ${item.material} para o estoque primário?`)) {
      await AxiosPost(`${Config.dominio}/php/estoque/estoque_editar_material.php`,
        JSON.stringify({
          protocolo: this.props.protocolo,
          material: item.idmaterial,
          quantidade: item.quantidade,
          idestoque: 1,
          movimentacao: 'S'
        })
      )
        .then(async response => {
          this.setState({ lstMaterialRemovido: removerMaterial })
        })
        .catch(erro => console.log(erro))
      await AxiosPost(`${Config.dominio}/php/estoque/estoque_delete.php`,
        JSON.stringify({
          id: item.id
        })
      )
        .then(async response => {
          this.setState({ lstMaterialRemovido: removerMaterial })
        })
        .catch(erro => console.log(erro))
    }
  }

  async concluirInserirMaterial() {
    let _lista = this.state.lstMaterialIncluido
    let lstQuantidade = []
    let lstIdMaterial = []

    _lista.map(item => {
      lstQuantidade.push(item.quantidade)
      lstIdMaterial.push(item.material.id)
    })
    await AxiosPost(`${Config.dominio}/php/estoque/estoque_inserir_ponto.php`,
      JSON.stringify({
        protocolo: this.props.protocolo,
        material: lstIdMaterial.toString(),
        quantidade: lstQuantidade.toString(),
        idestoque: 0,
        movimentacao: 'E'
      })
    )
      .then(async response => {
        //console.log(response)
        this.setState({ quantidade: [], material: [], editIncluirMateriais: false, lstMaterialIncluido: _lista })
      })
      .catch(erro => console.log(erro))
  }

  async concluirRemoverMaterial() {
    let lstQuantidade = []
    let lstIdMaterial = []
    this.state.lstMaterialRemovido.map(item => {
      lstQuantidade.push(item.quantidade)
      lstIdMaterial.push(item.material.id)
    })
    await AxiosPost(`${Config.dominio}/php/estoque/estoque_remover_ponto.php`,
      JSON.stringify({
        protocolo: this.props.protocolo,
        material: lstIdMaterial.toString(),
        quantidade: lstQuantidade.toString(),
        idestoque: 0,
        movimentacao: 'E'
      })
    )
      .then(async response => {
        this.setState({ quantidade: [], material: [], editRemoverMateriais: false })
      })
      .catch(erro => console.log(erro))
  }

  async materialInserido(protocolo) {
    await AxiosPost(`${Config.dominio}/php/estoque/estoque_list.php`,
      JSON.stringify({ protocolo: protocolo, estoque: 0, movimentacao: 'E' }))
      .then(async response => {
        this.setState({ lstMaterialIncluido: response.data })

      })
      .catch(erro => console.log(erro))
  }

  async materialRemovido(protocolo) {
    await AxiosPost(`${Config.dominio}/php/estoque/estoque_list.php`,
      JSON.stringify({ protocolo: protocolo, estoque: 0, movimentacao: 'S' }))
      .then(async response => {
        this.setState({ lstMaterialRemovido: response.data })
      })
      .catch(erro => console.log(erro))
  }



  async componentDidMount() {
    let protocolo = this.props.protocolo
    this.abertura(protocolo)
    this.autorizacao(protocolo)
    this.distribuicao(protocolo)
    this.baixa(protocolo)
    this.materialInserido(protocolo)
    this.materialRemovido(protocolo)
    this.materiais()

  }

  render() {
    return (
      <>
        <ReactTooltip />

        <Modal
          show={this.state.editIncluirMateriais}
          containerStyle={{ background: 'white', width: '1014px', minHeight: '410px' }}
          transitionSpeed={100}
        >
          <section className="content">
            <center><h3>Incluir materiais na Ordem de Serviço</h3></center>
            <div className="col-md-12">
              <div className="box box-success">
                <div className="box-body" >
                  <div className="row">
                    <div className="col-xs-8">
                      <div className="form-group">
                        <label htmlFor="Material">Material</label>

                        <Select
                          closeMenuOnSelect={true}
                          components={animatedComponents}
                          options={this.state.lstMateriais}
                          style={{ width: '100%' }}
                          value={this.state.materialItem}
                          onChange={selectedOption => this.setState({ materialItem: selectedOption })}
                        />
                      </div>
                    </div>
                    <div className="col-xs-2">
                      <div className="form-group">
                        <label htmlFor="quantidade">Quantidade</label>
                        <input
                          type="text"
                          style={{ textAlign: "right" }}
                          className="form-control"
                          placeholder="quantidade"
                          value={this.state.materialQuantidade || 1}
                          onChange={event => this.setState({ materialQuantidade: event.target.value })}
                        />
                      </div>
                    </div>
                    <div className="col-xs-2">
                      <div className="form-group">
                        <label htmlFor="botao">&nbsp;</label>
                        <button
                          type="button"
                          onClick={() => this.incluirMateriaisAdicionarItens()}
                          className="btn btn-block btn-primary"
                        >
                          Incluir
                        </button>
                      </div>
                    </div>
                  </div>

                  {this.state.lstMaterialIncluido.length > 0 &&
                    <div className="row" style={scrollList}>
                      <table className="table table-bordered table-striped">
                        <thead>
                          <tr>
                            <th style={{ whiteSpace: "nowrap" }}> Material</th>
                            <th style={{ width: "1px", textAlign: "center", whiteSpace: "nowrap" }}>Qtd</th>
                            <th style={{ width: "1px", textAlign: "center", whiteSpace: "nowrap" }}>&nbsp;</th>
                          </tr>
                        </thead>
                        <tbody>

                          {

                            this.state.lstMaterialIncluido.map((item, index) =>
                              <tr key={index} style={{ display: `${item.remover ? "none" : "table-row"}` }}>
                                <td style={{ verticalAlign: "middle" }}>{item.novo != true ? item.material : item.material.descricao}</td>
                                <td style={{ textAlign: "right", verticalAlign: "middle" }}>{item.quantidade}</td>
                                <td style={{ verticalAlign: "middle" }}><button type="button" className="btn btn-warning" onClick={() => this.incluirMateriaisRemoverItens(index, item)}><i className="fa fa-exchange"></i></button></td>
                              </tr>
                            )
                          }
                        </tbody>
                      </table>
                    </div>
                  }
                </div>
                <div className="box-footer">
                  <div className="col-xs-12">
                    <button
                      onClick={e => this.setState({ editIncluirMateriais: false })}
                      className="btn btn-warning btn-flat"
                    >
                      Cancelar
                    </button>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <button
                      onClick={e => this.concluirInserirMaterial()}
                      className="btn btn-info btn-flat"
                    >
                      Concluir
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Modal>

        <Modal
          show={this.state.editRemoverMateriais}
          containerStyle={{ background: 'white', width: '1014px', minHeight: '410px' }}
          transitionSpeed={100}
        >
          <section className="content">
            <center><h3>Remover materiais na Ordem de Serviço</h3></center>
            <div className="col-md-12">
              <div className="box box-success">
                <div className="box-body" >
                  <div className="row">
                    <div className="col-xs-8">
                      <div className="form-group">
                        <label htmlFor="Material">Material</label>
                        <Select
                          closeMenuOnSelect={true}
                          components={animatedComponents}
                          options={this.state.lstMateriais}
                          style={{ width: '100%' }}
                          value={this.state.materialItem}
                          onChange={selectedOption => this.setState({ materialItem: selectedOption })}
                        />
                      </div>
                    </div>
                    <div className="col-xs-2">
                      <div className="form-group">
                        <label htmlFor="quantidade">Quantidade</label>
                        <input
                          type="text"
                          style={{ textAlign: "right" }}
                          className="form-control"
                          placeholder="quantidade"
                          value={this.state.materialQuantidade || 1}
                          onChange={event => this.setState({ materialQuantidade: event.target.value })}
                        />
                      </div>
                    </div>
                    <div className="col-xs-2">
                      <div className="form-group">
                        <label htmlFor="botao">&nbsp;</label>
                        <button
                          type="button"
                          onClick={() => this.removerMateriaisAdicionarItens()}
                          className="btn btn-block btn-primary"
                        >
                          Incluir
                        </button>
                      </div>
                    </div>
                  </div>

                  {this.state.lstMaterialRemovido.length > 0 &&
                    <div className="row" style={scrollList}>
                      <table className="table table-bordered table-striped">
                        <thead>
                          <tr>
                            <th style={{ whiteSpace: "nowrap" }}> Material</th>
                            <th style={{ width: "1px", textAlign: "center", whiteSpace: "nowrap" }}>Qtd</th>
                            <th style={{ width: "1px", textAlign: "center", whiteSpace: "nowrap" }}>&nbsp;</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            this.state.lstMaterialRemovido.map((item, index) =>
                              <tr key={index} style={{ display: `${item.remover ? "none" : "table-row"}` }}>
                                <td style={{ verticalAlign: "middle" }}>{item.novo != true ? item.material : item.material.descricao}</td>
                                <td style={{ textAlign: "right", verticalAlign: "middle" }}>{item.quantidade}</td>
                                <td style={{ verticalAlign: "middle" }}><button type="button" className="btn btn-warning" onClick={() => this.removerMateriaisRemoverItens(index, item)}><i className="fa fa-exchange"></i></button></td>
                              </tr>
                            )
                          }
                        </tbody>
                      </table>
                    </div>
                  }
                </div>
                <div className="box-footer">
                  <div className="col-xs-12">
                    <button
                      onClick={e => this.setState({ editRemoverMateriais: false })}
                      className="btn btn-warning btn-flat"
                    >
                      Cancelar
                    </button>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <button
                      onClick={e => this.concluirRemoverMaterial()}
                      className="btn btn-info btn-flat"
                    >
                      Concluir
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Modal>

        <section className="content-header">
          <h1>
            OS: {this.props.protocolo}
            <small>Detalhes da Ordem de Serviço</small>
          </h1>
        </section>
        <section className="content">
          <div className="row">
            <div className="col-md-12">
              <ul className="timeline">
                <li className="time-label">
                  <span className="bg-red">{this.state.abertura && this.state.abertura.datahora} - Abertura da OS 
                  </span>&nbsp;
                  <button 
type="button" 
className="btn btn-danger btn-flat" 
onClick={() => ReactDOM.render(<LstOrdensDeServico />, document.getElementById('Content'))}>Voltar</button>
                </li>
                <li><i className="fa fa-bullhorn bg-blue"></i>
                  <div className="timeline-item">
                    <span className="time"><i className="fa fa-edit"></i></span>
                    <h3 className="timeline-header">Equipe de Atendimento</h3>
                    <div className="timeline-body">
                      Notificação de manutenção do Ponto de Iluminação no endereço {this.state.abertura && `${this.state.abertura.rua}, ${this.state.abertura.bairro}`}.
                    </div>
                  </div>
                </li>
                <li><i className="fa fa-user bg-aqua"></i>
                  <div className="timeline-item">
                    <span className="time"><i className="fa fa-edit"></i> </span>
                    <h3 className="timeline-header no-border"><a href="#">{this.state.abertura && this.state.abertura.nome}</a></h3>
                    <div className="timeline-body">
                      Telefone: {this.state.abertura && this.state.abertura.telefone} {this.state.abertura && this.state.abertura.sms == 1 && '(autorizou enviar sms)'}
                      <p />
                      E-mail: {this.state.abertura && this.state.abertura.email}
                    </div>
                  </div>
                </li>
                <li><i className="fa fa-comments bg-yellow"></i>
                  <div className="timeline-item">
                    <span className="time"><i className="fa fa-edit"></i> </span>
                    <h3 className="timeline-header"><a href="#">Problema relatado</a> </h3>
                    <div className="timeline-body">
                      {this.state.abertura && this.state.abertura.descricao}<br />
                      {this.state.abertura && this.state.abertura.descricao_problema}
                    </div>
                  </div>
                </li>
                {this.state.autorizacao && <>
                  <li className="time-label"><span className="bg-red">{this.state.autorizacao && this.state.autorizacao.datahora} - Autorização</span></li>
                  <li><i className="fa fa-thumbs-o-up bg-blue"></i>
                    <div className="timeline-item">
                      <h3 className="timeline-header">{usuario().municipio.razaoSocial}</h3>
                      <div className="timeline-body">
                        Autorização para execução da Ordem de Serviço.
                      </div>
                    </div>
                  </li>
                </>}
                {this.state.distribuicao && this.state.distribuicao.length > 0 && <>
                  <li className="time-label"><span className="bg-red">{this.state.distribuicao && this.state.distribuicao[0].datahora} - Distribuição entre as equipes</span></li>
                  <li><i className="fa fa-arrows-alt bg-blue"></i>
                    <div className="timeline-item">
                      <h3 className="timeline-header">Coordenação das atividades de manutenção</h3>
                      <div className="timeline-body">
                        {this.state.distribuicao &&
                          this.state.distribuicao.map(item => <div>({item.datahora}) {item.observacao}</div>)
                        }
                      </div>
                    </div>
                  </li>
                </>}
                {this.state.baixa && <>
                  <li className="time-label"><span className="bg-red">{this.state.baixa && this.state.baixa.datahora} - Baixa da OS</span></li>
                  <li><i className="fa fa-flag-checkered bg-green"></i>
                    <div className="timeline-item">
                      <span className="time"><i className="fa fa-edit"></i> </span>
                      <h3 className="timeline-header">Equipe de Manutenção</h3>
                      {this.state.baixa && this.state.baixa.id_status == 4 &&
                        <div className="timeline-body">
                          Manutenção realizada no ponto de iluminação no período de {this.state.baixa.datahora_inicio} até {this.state.baixa.datahora_fim}.
                        </div>
                      }
                      {this.state.baixa && this.state.baixa.id_status == 6 &&
                        <div className="timeline-body">
                          Manutenção cancelada a pedido do usuário.
                        </div>
                      }
                      {this.state.baixa && this.state.baixa.id_status == 7 &&
                        <div className="timeline-body">
                          Notificação FALSA. Tomar medidas cabíveis.
                        </div>
                      }
                    </div>
                  </li>
                  {this.state.baixa && this.state.baixa.id_status == 4 && <>
                    <li><i className="fa fa-user bg-aqua"></i>
                      <div className="timeline-item">
                        <h3 className="timeline-header no-border"><a href="#">Informações do técnico</a></h3>
                        <div className="timeline-body">
                          Atividade Realizada:<br />
                          {this.state.baixa.atividade_realizada}
                          <p />
                          Observações do Técnico:<br />
                          {this.state.baixa.observacao_tecnico}
                        </div>
                      </div>
                    </li>
                    <li><i className="fa fa-gears bg-yellow"></i>
                      <div className="timeline-item">
                        <span className="time"></span>
                        <h3 className="timeline-header"><a href="#">Materiais</a> </h3>
                        <div className="timeline-body">
                          <b>Materiais incluídos</b> <a href="#" onClick={() => this.setState({ editIncluirMateriais: !this.state.editIncluirMateriais })}><i className="fa fa-edit"></i></a> <br />
                          {this.state.materialInserido &&
                            <table className="table table-bordered table-striped">
                              <thead>
                                <tr>
                                  <th style={{ whiteSpace: "nowrap" }}>Descrição</th>
                                  <th style={{ width: "1px", whiteSpace: "nowrap" }}>Quantidade</th>
                                  <th style={{ width: "1px", whiteSpace: "nowrap" }}>&nbsp;</th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.materialInserido.map((item, index) => <tr key={index}>
                                  <td style={{ whiteSpace: "nowrap" }}>{item.material}</td>
                                  <td style={{ width: "1px", textAlign: "center", whiteSpace: "nowrap" }}>{item.quantidade}</td>
                                  <td style={{ width: "1px", textAlign: "center", whiteSpace: "nowrap" }}><a href="#" onClick={() => console.log("excluir esse item")}><i data-tip={`Remover este item`} className="fa fa-trash"></i></a></td>
                                </tr>)}
                              </tbody>
                            </table>
                          }

                          <p />
                          <b>Materiais removidos</b> <a href="#" onClick={() => this.setState({ editRemoverMateriais: !this.state.editRemoverMateriais })}><i className="fa fa-edit"></i></a> <br />
                          {this.state.materialRemovido &&
                            <table className="table table-bordered table-striped">
                              <thead>
                                <tr>
                                  <th style={{ whiteSpace: "nowrap" }}>Descrição</th>
                                  <th style={{ width: "1px", whiteSpace: "nowrap" }}>Quantidade</th>
                                  <th style={{ width: "1px", whiteSpace: "nowrap" }}>&nbsp;</th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.materialRemovido.map((item, index) =>
                                  <tr key={index}>
                                    <td style={{ whiteSpace: "nowrap" }}>{item.material}</td>
                                    <td style={{ width: "1px", textAlign: "center", whiteSpace: "nowrap" }}>{item.quantidade}</td>
                                    <td style={{ width: "1px", textAlign: "center", whiteSpace: "nowrap" }}><a href="#" onClick={() => console.log("excluir esse item")}><i data-tip={`Remover este item`} className="fa fa-trash"></i></a></td>
                                  </tr>)}
                              </tbody>
                            </table>
                          }
                        </div>
                      </div>
                    </li>
                  </>}
                </>}
              </ul>
            </div>
          </div>
        </section>
      </>
    )
  }
}
