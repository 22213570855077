import React from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "../../../App.css";
import "leaflet/dist/leaflet.css";


const position = [-8.835053049999999, -36.47063727944714]

export default function MapaDashBoard() {
    return (
        <section className="content">
            <div className="box box-info">
                <div className="box-body" >
                    <div className="form-group" style={{ height: '660px' }}>
                        <MapContainer
                            center={position}
                            zoom={15}
                            style={{ height: '660px' }}

                        >
                            <TileLayer
                                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />

                            <Marker position={position}>
                                <Popup>
                                    A pretty CSS3 popup. <br /> Easily customizable.
                                </Popup>
                            </Marker>
                        </MapContainer>
                    </div>
                </div>
            </div>
        </section>
    );
}
