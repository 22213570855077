import React, { useState } from 'react'
import Axios from 'axios'
import Modal from 'simple-react-modal'
import ClipLoader from "react-spinners/ClipLoader"
import Cookie from 'universal-cookie'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import InputMask from 'react-input-mask'
import Config from '../config'

import { criptografar } from '../lib/utils2'

import logo from '../assets/jca_log.png'

import './styles.css'

const cookie = new Cookie()

export default function Chave() {

  const [chave, setChave] = useState('')
  const [lembrarChave, setLembrarChave] = useState(false)
  const [showModal, setShowModal] = useState(false)

  async function handleSubmit(event) {
    event.preventDefault()
    setShowModal(true)
    await Axios.post( `${Config.dominio}/php/chave.php`, 
                      JSON.stringify({
                                      chave: chave,
                                      local: sessionStorage.getItem('local')
                                    })
    )
    .then(async (response) => {
      if (response.data.length==0) {
        setShowModal(false)
        toast.error(`Chave inválida. Verifique.`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      } else {
        if (lembrarChave) {
          cookie.set('chave', criptografar(chave), { maxAge : lembrarChave? 365*24*60*60 : 60 }) // segundos
        } else {
          sessionStorage.setItem('chave', criptografar(chave))
        }
         document.location = '/'
      }
    })
    .catch(erro => console.log(erro))

  }

  return (
    <>
      <Modal show={showModal} containerStyle={{ background: 'Transparent' }} transitionSpeed={100}>
        <center>
          <ClipLoader className="override" color={"#123abc"} loading={true} />
        </center>
      </Modal>
      <img className="logoJCA" src={logo} alt="" />
      <div className="linha-vertical"></div>
      <div id="loginForm" className="contentLogin">
        <h2 align="center">Gestão de Iluminação Pública</h2>
        <form onSubmit={handleSubmit}>
          <label htmlFor="chave">Chave *</label>
          <InputMask
            id="chave"
            placeholder="Chave de acesso"
            maskPlaceholder={null}
            value={chave}
            onChange={event => setChave(event.target.value)}
          />
          <label htmlFor="lembrarChave" style={{verticalAlign: '10px', wordWrap: 'break-word'}} >
            <input
              type="checkbox"
              id="lembrarChave"
              style={{verticalAlign: 'top', overflowWrap: 'break-word', marginBottom: 0, marginTop: 2, height: 15}}
              onChange={event => setLembrarChave(event.target.checked)}
            /> &nbsp;Lembrar Chave
          </label>

          <button className="btn" type="submit">Entrar</button>
        </form>
      </div>
      <ToastContainer />
    </>
  )
}