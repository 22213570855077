import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import Modal from 'simple-react-modal'
import { format } from 'date-fns'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import LstEntrada from './lstEntrada'
import { AxiosPost } from '../../../lib/utils3'

import Config from '../../../config'

const cssToast = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined
}

export default class entradaEstoque extends Component {

  state = {material: null,
           valor: "",
           quantidade: "",
           lstMaterial: [],
           documento: null,
           fornecedor: null,
           emissao: null,
           lstItens:[],
           showModal: false,
           id: null
          }
  adicionarItens = this.adicionarItens.bind(this)
  handleSubmit = this.handleSubmit.bind(this)
  buscarDados = this.buscarDados.bind(this)
  confirmaExcluir = this.confirmaExcluir.bind(this)

  async handleSubmit(e) {
    e.preventDefault()
    let _itens_Material = []
    let _itens_valor = []
    let _itens_qtd = []
    this.state.lstItens.map(item => {
                                      _itens_Material.push(item.material.id)
                                      _itens_valor.push(item.valor)
                                      _itens_qtd.push(item.quantidade)
                                    }
                           )
    await AxiosPost(`${Config.dominio}/php/estoque/estoque_entrada.php`,
                      JSON.stringify({
                                      documento: this.state.documento,
                                      fornecedor: this.state.fornecedor,
                                      emissao: format(new Date(this.state.emissao + " 00:00:00"), "yyyy-MM-dd 00:00:00"),
                                      material: _itens_Material.toString(),
                                      quantidade: _itens_qtd.toString(),
                                      valor: _itens_valor.toString()
                                    })
      )
      .then(async (response) => {
        toast.success('Entrada registrada!', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          //onClose: ReactDOM.render(<LstEntrada />, document.getElementById('Content'))
        })
      })
      .catch(erro => console.log(erro))
  }

  async buscarMateriais() { 
    await AxiosPost(`${Config.dominio}/php/materiais_list.php`,
                      JSON.stringify({a: 1})
    )
    .then(async function (response) {
      this.setState({lstMaterial: response.data})
    }.bind(this))
    .catch(erro => console.log(erro))
  }

  async buscarDados(id) {
    await AxiosPost(`${Config.dominio}/php/estoque_list.php`,
                     JSON.stringify({
                      idestoque_fornecedor: id
                     })
    )
    .then(async function (response) {
      let lista = []
      response.data.map(item => lista.push({material: {
                                                        codigo: item.codigo,
                                                        descricao: item.material,
                                                        grupo_desc: item.grupo
                                                      },
                                            quantidade: item.quantidade,
                                            valor: item.valor
                                          }))
      this.setState({
                      documento: response.data[0].documento,
                      fornecedor: response.data[0].fornecedor,
                      emissao: format(new Date(response.data[0].dataemissao + " 00:00"), "dd/MM/yyyy"),
                      lstItens: lista
                  })
    }.bind(this))
    .catch(erro => console.log(erro))

  }

  cancelaExcluir(){
    ReactDOM.render(<LstEntrada />, document.getElementById('Content'))
  }

  async confirmaExcluir() {
    await AxiosPost(`${Config.dominio}/php/estoque/estoque_delete.php`, 
          JSON.stringify({ 
                          id: this.state.id
                        })
    )
    .then(async function (response) {
      toast.info('Lançamentos removidos!', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        onClose: () => ReactDOM.render(<LstEntrada />, document.getElementById('Content'))
        })
    })
    .catch(erro => console.log(erro))
}

  adicionarItens(e) {
    if (!this.state.material) {
      toast.warning('Material é obrigatório!', cssToast)
    } else{
      if (!this.state.valor) {
        toast.warning('Valor é obrigatório!', cssToast)
      } else {
        if (!this.state.quantidade) {
          toast.warning('Quantidade é obrigatória!', cssToast)
        } else {
          let _lista = this.state.lstItens
          _lista.push({material: this.state.lstMaterial.find(item => item.id == this.state.material),
                       quantidade: this.state.quantidade,
                       valor: this.state.valor
                     })
          this.setState({lstItens: _lista, 
                         material: null,
                         valor: null,
                         quantidade: null
                       })
          document.getElementById("lstMaterial").selectedIndex=0
          document.getElementById("quantidade").value=""
          document.getElementById("valor").value=""
        }
      }
    }
  }
  
  removerItem (item) {
    let _lista = this.state.lstItens
    _lista.splice(item, 1)
    this.setState({lstItens: _lista})
  }

  componentWillMount() {
    this.buscarMateriais()
  }

  componentDidMount() {
    this.setState({emissao: format(new Date(), "dd/MM/yyyy")})
    if (this.props.action) {
      this.setState({id: this.props.id, 
                     showModal: this.props.action=="delete"
                   })
      this.buscarDados(this.props.id)
      document.getElementById("entradaDados").style.display='none'
      document.getElementById("btnSalvar").style.display='none'  
    }
  }

  render() {
    return(
      <>
        <ToastContainer />
        <Modal 
          show={this.state.showModal} 
          containerStyle={{background: 'Transparent'}} 
          transitionSpeed={400}
        >
          <div className="box box-danger">
            <div className="box-body">
              <div className="row">
                <div className="col-xs-12">
                  <center>
                    <h4>Confirma excluir essa Entrada?</h4>
                    Atenção!<br />
                    Todos os lançamentos de materiais serão removidos!
                  </center>
                </div>
              </div>
            </div>
            <div className="box-footer">
              <center>
                <button 
                  id="btnExcluir" 
                  onClick={this.confirmaExcluir} 
                  className="btn btn-danger btn-flat"
                >
                  Excluir
                </button>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <button 
                  onClick={this.cancelaExcluir} 
                  className="btn btn-warning btn-flat"
                >
                  Cancelar
                </button>
              </center>
            </div>
          </div>
        </Modal>
        <section className="content-header">
          <h1>
          Estoque
            <small>Entrada</small>
          </h1>
          <ol className="breadcrumb">
            <li>
              <button 
                type="button" 
                className="btn btn-danger btn-flat" 
                onClick={() => ReactDOM.render(<LstEntrada />, document.getElementById('Content'))}
              >Voltar</button>&nbsp;&nbsp;
              <button id="btnSalvar" className="btn btn-primary" type="button" onClick={this.handleSubmit} >Salvar</button>
            </li>
          </ol>
        </section>
        <section className="content">
            <div className="box box-success">
              <div className="box-header with-border">
                <h3 className="box-title">Informações da nota</h3>
              </div>
              <div className="box-body">
                <div className="row">
                  <div className="col-xs-2">
                    <div className="form-group">
                      <label htmlFor="Grupo">Documento</label>
                      <input 
                        type="text"
                        className="form-control"
                        placeholder="documento"
                        value={this.state.documento}
                        onChange={event=> {this.setState({documento: event.target.value})}}
                      />
                    </div>
                  </div>
                  <div className="col-xs-8">
                    <div className="form-group">
                      <label htmlFor="Grupo">Fornecedor</label>
                      <input 
                        type="text"
                        className="form-control"
                        placeholder="Fornecedor"
                        valueDefault={this.state.fornecedor}
                        value={this.state.fornecedor}
                        onChange={event=> {this.setState({fornecedor: event.target.value})}}
                      />
                    </div>
                  </div>
                  <div className="col-xs-2">
                    <div className="form-group">
                      <label htmlFor="Grupo">Emissão</label>
                      <input 
                        type="date"
                        className="form-control"
                        placeholder="dd/mm/aaaa"
                        value={this.state.emissao}
                        onChange={event=> {this.setState({emissao: event.target.value})}}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="entradaDados" className="box box-success">
              <div className="box-header with-border">
                <h3 className="box-title">Itens a incluir</h3>
              </div>
              <div className="box-body" >
                <div className="row">
                  <div className="col-xs-6">
                    <div className="form-group">
                      <label htmlFor="Material">Material</label>
                      <select
                        id="lstMaterial"
                        className="form-control select2" 
                        style={{width: '100%'}}
                        value={this.state.material}
                        onChange={event => this.setState({material: event.target.value})}
                      >
                        <option value="">Selecione o Material</option>
                        {
                          this.state.lstMaterial && this.state.lstMaterial.map((opt, index) => <option key={index} value={opt.id}>{opt.descricao}</option>)
                        }
                      </select>
                    </div>
                  </div>
                  <div className="col-xs-2">
                    <div className="form-group">
                      <label htmlFor="quantidade">Quantidade</label>
                      <input 
                      id="quantidade"
                      type="text"
                      style={{textAlign: "right"}}
                      className="form-control"
                      placeholder="quantidade"
                      defaultValue={this.state.quantidade}
                      value={this.state.quantidade}
                      onChange={event=> this.setState({quantidade: event.target.value})}
                      />
                    </div>
                  </div>
                  <div className="col-xs-2">
                    <div className="form-group">
                      <label htmlFor="valor">Valor</label>
                      <input 
                        id="valor"
                        type="text"
                        style={{textAlign: "right"}}
                        className="form-control"
                        placeholder="valor unitário"
                        defaultValue={this.state.valor}
                        value={this.state.valor}
                        onChange={event=> this.setState({valor: event.target.value})}
                      />
                    </div>
                  </div>
                  <div className="col-xs-2">
                    <div className="form-group">
                      <label htmlFor="botao">&nbsp;</label>
                      <button
                        type="button"
                        onClick={() => this.adicionarItens()}
                        className="btn btn-block btn-primary" 
                      >
                        Incluir
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="box-footer">      
              </div>
            </div>
            { this.state.lstItens.length>0 &&
            <div className="box box-success">
              <div className="box-header with-border">
                <h3 className="box-title">Itens da nota</h3>
              </div>
              <div className="box-body">
                <table className="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th style={{width: "1px", textAlign: "center", whiteSpace: "nowrap"}}>&nbsp;</th>
                      <th style={{width: "1px", textAlign: "center", whiteSpace: "nowrap"}}>Código</th>
                      <th style={{width: "1px", textAlign: "center", whiteSpace: "nowrap"}}>Tipo</th>
                      <th style={{whiteSpace: "nowrap"}}> Material</th>
                      <th style={{width: "1px", textAlign: "center", whiteSpace: "nowrap"}}>Qtd</th>
                      <th style={{width:"1px", textAlign: "right", whiteSpace: "nowrap"}}>Valor unit.</th>
                      <th style={{width:"1px", textAlign: "right", whiteSpace: "nowrap"}}>Total</th>
                      <th style={{width: "1px", textAlign: "center", whiteSpace: "nowrap"}}>&nbsp;</th>
                    </tr>
                  </thead>
                  <tbody>
                  {
                    this.state.lstItens.map((item, index) => 
                      <tr key={index}>
                        <td style={{textAlign: "center"}}>{index+1}</td>
                        <td>{item.material.codigo}</td>
                        <td>{item.material.grupo_desc}</td>
                        <td>{item.material.descricao}</td>
                        <td style={{textAlign: "right"}}>{item.quantidade}</td>
                        <td style={{textAlign: "right"}}>{(1*item.valor).toFixed(2)}</td>
                        <td style={{textAlign: "right"}}>{(item.valor * item.quantidade).toFixed(2)}</td>
                        <td><button style={{display:`${this.props.action? 'none': 'block'}`}} type="button" className="btn btn-danger" onClick={() => this.removerItem(index)}><i className="fa fa-trash"></i></button></td>
                      </tr>
                    )
                  }
                  </tbody>
                  <tfoot>
                  <tr>
                      <th style={{width: "1px", textAlign: "center", whiteSpace: "nowrap"}}>&nbsp;</th>
                      <th style={{width: "1px", textAlign: "center", whiteSpace: "nowrap"}}>Código</th>
                      <th style={{width: "1px", textAlign: "center", whiteSpace: "nowrap"}}>Tipo</th>
                      <th style={{whiteSpace: "nowrap"}}> Material</th>
                      <th style={{width: "1px", textAlign: "center", whiteSpace: "nowrap"}}>Qtd</th>
                      <th style={{width:"1px", textAlign: "right", whiteSpace: "nowrap"}}>Valor unit.</th>
                      <th style={{width:"1px", textAlign: "right", whiteSpace: "nowrap"}}>Total</th>
                      <th style={{width: "1px", textAlign: "center", whiteSpace: "nowrap"}}>&nbsp;</th>
                    </tr>
                  </tfoot>
                </table>
              </div>
              <div className="box-footer">      
              </div>
            </div>
            }
        </section>
      </>
    )
  }
}