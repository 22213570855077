import React from 'react'

export default function Footer() {
 
  return (
    <div>
      <footer className="main-footer">
        <div className="pull-right hidden-xs">
            Versão 2021.01 
            <i className="fa fa-lightbulb-o text-orange" /> 
            <strong>JCA Solutions Ltda - (tm)</strong>
        </div>
      </footer>
    </div>
  )
}
