import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import ClipLoader from "react-spinners/ClipLoader"
import { addDays, format } from 'date-fns'
import { usuario } from '../../../lib/utils2'
import { AxiosPost } from '../../../lib/utils3'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import Config from '../../../config'

export default class lstSsaidaEstoque extends Component {

  state = {result: null,
                  material: null,
                  grupo: null,
                  valor: "0",
                  quantidade: "",
                  lstMaterial: [],
                  lstMaterialShow: [],
                  lstGrupo: [],
                  lstSaldo: [],
                  pag_atual: 1
                 }
  buscarGrupos = this.buscarGrupos.bind(this)
  buscarMateriais = this.buscarMateriais.bind(this)
  incluirEstoque = this.incluirEstoque.bind(this)
  
  async buscarGrupos() { 
    await AxiosPost(`${Config.dominio}/php/grupoMateriais_list.php`,
                      JSON.stringify({ a: 1 })
    )
    .then(async function (response) {
      let lista = []
      response.data.map(item => lista.push({id: item.vgrupo_id, 
                                                    grupo: item.vgrupo_desc, 
                                                    level: item.level}))
      this.setState({lstGrupo: lista})
    }.bind(this))
  }

  async buscarMateriais() {
    await AxiosPost(`${Config.dominio}/php/materiais_list.php`,
                    JSON.stringify({ a: 1 })
    )
    .then(async function (response) {
      let lista = []
      response.data.map((item,index) => lista.push({id: item.id, 
                                                    descricao: item.descricao, 
                                                    codigo: item.codigo, 
                                                    grupo: item.grupo_id, 
                                                    unidade_medida: item.unidade_medida_desc, 
                                                    unidades: item.unidades}))
      this.setState({lstMaterial: lista})
    }.bind(this))
  }

  async incluirEstoque(e) {
    e.preventDefault()
    await AxiosPost(`${Config.dominio}/php/estoque/estoque_entrada.php`, 
                      JSON.stringify({material: this.state.material,
                                      quantidade: this.state.quantidade,
                                      valor: this.state.valor
                                    })
    )
    .then(async function (response) {
      // toast ok
      toast.info('Material armazenado com sucesso!', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      })
    })
    .then(() => this.setState({ material: "", grupo: "", valor: "", quantidade: "" }))
    .then(() => this.buscarMovimentacao())
    .catch( erro => console.log(erro))
  }
  
  async buscarSaldo() {
    await AxiosPost(`${Config.dominio}/php/estoque/estoque_saldo.php`,
                    JSON.stringify({
                      movimentacao: 'E',
                      local: sessionStorage.getItem('local'),
                      chaveMunicipio: usuario().municipio.chave
                    })
    )
    .then(async function (response) {
      let lista = []
      response.data.map((item,index) => lista.push({material: item.material,
                                                    id: item.id,
                                                    idestoque: item.idestoque,
                                                    quantidade_total: item.quantidade_total,
                                                    valor_total: item.valor_total
                                                  }))
      this.setState({lstSaldo: lista})
    }.bind(this))
  }

  async buscarMovimentacao() {
    await AxiosPost(`${Config.dominio}/php/estoque/estoque_list.php`,
                      JSON.stringify({ movimentacao: 'S', estoque: 1})
    )
    .then(async function (response) {
      let lista = []
      response.data.map((item,index) => lista.push({id: item.idmaterial, 
                                                    descricao: item.material,
                                                    data: item.data,
                                                    valor: item.valor,
                                                    quantidade: item.quantidade
                                                  }))
      lista.sort((a, b) => a.data < b.data? 1:-1)
      this.setState({result: lista})
    }.bind(this))
  }

  componentDidMount() {
    this.buscarGrupos()
    this.buscarMateriais()
    this.buscarMovimentacao()
    this.buscarSaldo()
  }

  relatorio() {
    if (this.state.result){
      if (this.state.result.length == 0 ) {
        return (
          <center><h2>Não há registros de saída no estoque.</h2></center>
        )
      }
        
      return (
      <table className="table table-bordered table-striped">
        <thead>
          <tr>
            <th style={{width: "1px", textAlign: "center", whiteSpace: "nowrap"}}>Data Saída</th>
            <th style={{whiteSpace: "nowrap"}}> Material</th>
            <th style={{width:"1px", textAlign: "right", whiteSpace: "nowrap"}}>Quantidade</th>
            <th style={{width:"1px", textAlign: "right", whiteSpace: "nowrap"}}>Observação</th>
          </tr>
        </thead>
        <tbody>
          {this.state.result.map((item, index) =>
            <tr key={index}>
              <td style={{textAlign: "center", whiteSpace: "nowrap"}}>{format(new Date(item.data), "dd/MM/yyyy HH:mm")}</td>
              <td style={{whiteSpace: "nowrap"}}>{item.descricao}</td>
              <td style={{textAlign: "right"}}>{item.quantidade}</td>
              <td style={{textAlign: "right"}}>{item.observacao}</td>
            </tr>
          )}
        </tbody>
        <tfoot>
          <tr>
            <th style={{width: "1px", textAlign: "center", whiteSpace: "nowrap"}}>Data Saída</th>
            <th style={{whiteSpace: "nowrap"}}>Material</th>
            <th style={{width:"1px", textAlign: "right", whiteSpace: "nowrap"}}>Quantidade</th>
            <th style={{width:"1px", textAlign: "right", whiteSpace: "nowrap"}}>Observação</th>
          </tr>
        </tfoot>
      </table>
      )
    }
  }

  render() {
    return(
      <>
        <ToastContainer />
        <section className="content-header">
          <h1>
          Estoque
            <small>Saída</small>
          </h1>
        </section>
        <section className="content">
            <form onSubmit={this.incluirEstoque}>
            <div className="box box-success">
              <div className="box-header with-border">
                <h3 className="box-title">Saída de material do estoque</h3>
              </div>
              <div className="box-body">
                <div className="row">
                  <div className="col-xs-3">
                    <div className="form-group">
                      <label htmlFor="Grupo">Grupo</label>
                      <select
                        className="form-control select2" 
                        style={{width: '100%'}} 
                        value={this.state.grupo}
                        onChange={event => {
                                            this.setState({grupo: event.target.value})
                                            this.setState({lstMaterialShow: this.state.lstMaterial.filter(item => item.grupo == event.target.value)})
                                            }
                                  }
                      >
                        <option value="">Selecione o Grupo</option>
                        {
                          this.state.lstGrupo.map((opt, index) => <option key={index} value={opt.id}>{`${String("\u00A0").repeat(opt.level*4)}${opt.grupo}`}</option>)
                        }
                      </select>
                    </div>
                  </div>
                  <div className="col-xs-6">
                    <div className="form-group">
                      <label htmlFor="Material">Material</label>
                      <select
                        className="form-control select2" 
                        style={{width: '100%'}} 
                        value={this.state.material}
                        onChange={event => this.setState({material: event.target.value})}
                      >
                        <option value="">Selecione o Material</option>
                        {
                          this.state.lstMaterialShow && this.state.lstMaterialShow.map((opt, index) => <option key={index} value={opt.id}>{opt.descricao}</option>)
                        }
                      </select>
                    </div>
                  </div>
                  <div className="col-xs-2">
                    <div className="form-group">
                      <label htmlFor="funcao">Unidade</label>
                      <input 
                      type="text"
                      className="form-control"
                      value ={`${this.state.material ? this.state.lstMaterial.filter(item => item.id==this.state.material)[0].unidades : ''} ${this.state.material ? this.state.lstMaterial.filter(item => item.id==this.state.material)[0].unidade_medida: ''}`}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-2">
                    <div className="form-group">
                      <label htmlFor="Material">Quantidade a retirar</label>
                      <input 
                      type="text"
                      style={{textAlign: "right"}}
                      className="form-control"
                      placeholder="quantidade"
                      value={this.state.quantidade}
                      onChange={event=> {this.setState({quantidade: event.target.value})}}
                      />
                    </div>
                  </div>
                  <div className="col-xs-2">
                    
                  </div>
                  <div className="col-xs-2">
                    <div className="form-group">
                      <label htmlFor="Material">&nbsp;</label>
                      <button
                        type="submit"
                        className="btn btn-block btn-primary" 
                      >
                        Continuar
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="box-footer">      
              </div>
            </div>

            </form>
        </section>
        <section className="content">
          <div className="box">
            <div className="box-tools"></div>
          </div>
          <div className="box-body">
            <p />
            <ClipLoader
              css={{display: 'block', margin: '0 auto', borderColor: 'red'}}
              color={"#123abc"}
              loading={!this.state.result}
            />
            {this.relatorio()}
          </div>
        </section>
      </>
    )
  }
}