import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import Axios from 'axios'
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react'
import Geocode from 'react-geocode'
import Modal from 'simple-react-modal'
import LstPontosIluminacao from './lstPontosIluminacao'
import Utils from '../../../lib/utils'
import { format } from 'date-fns'
import { usuario } from '../../../lib/utils2'
import { AxiosPost } from '../../../lib/utils3'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import Config from '../../../config'

const animatedComponents = makeAnimated()
Geocode.setApiKey(Config.apiKey)

export class PontosIluminacao extends Component {

  state = {
    id: this.props.id,
    barramento: '',
    tombamento: '',
    telegestao: '',
    latitude: usuario().municipio.latitude,
    longitude: usuario().municipio.longitude,
    cep: '',
    logradouro: '',
    bairro: '',
    larguraVia: '',
    tipoPavimento: '',
    larguraPasseio: '',
    tipoMedicao: 0,
    conduc: '',
    tipoMaterial: '',
    altura: '',
    lstTipoPavimento: [],
    lstTipoMaterial: [],
    lstStatusOS: [],
    lstTipoMedicao: [],
    lstOS: [],
    lstItens: [],
    materialQuantidade: 1,
    materialItem: null,
    lstMaterial: [], // Material do ponto
    lstMateriaisRef: [], // lista de materiais disponíveis no estoque - conjunto não adaptado
    lstMateriais: [], // lista de materiais disponíveis no estoque - conjunto adaptado para o select
    showNovoItem: false,
    showModal: false
  }

  handleSubmit = this.handleSubmit.bind(this)
  buscarLocal = this.buscarLocal.bind(this)
  confirmaExcluir = this.confirmaExcluir.bind(this)

  cancelaExcluir() {
    ReactDOM.render(<LstPontosIluminacao />, document.getElementById('Content'))
  }

  async confirmaExcluir() {
    console.log(this.state.id)
    await AxiosPost(`${Config.dominio}/php/pontosIluminacao/pontosIluminacao_delete.php`,
      JSON.stringify({ id: this.state.id })
    )
      .then(async function (response) {
        toast.info('Ponto de Iluminação removido!', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          onClose: () => ReactDOM.render(<LstPontosIluminacao />, document.getElementById('Content'))
        })
      })
      .catch(function (erro) {
        console.log(erro)
      })
  }

  async listarTipoPavimento() {
    await AxiosPost(`${Config.dominio}/php/generica_list.php`,
      JSON.stringify({ tabela: 3 })
    )
      .then(async function (response) {
        let _lista = []
        response.data.map(item => _lista.push({ valor: item.valor, descricao: item.descricao }))
        this.setState({ lstTipoPavimento: _lista })
      }.bind(this))
      .catch(erro => console.log(erro))
  }

  async listarTipoMaterial() {
    await AxiosPost(`${Config.dominio}/php/generica_list.php`,
      JSON.stringify({ tabela: 2 })
    )
      .then(async function (response) {
        let _lista = []
        response.data.map(item => _lista.push({ valor: item.valor, descricao: item.descricao }))
        this.setState({ lstTipoMaterial: _lista })
      }.bind(this))
      .catch(erro => console.log(erro))
  }

  async listarStatusOS() {
    await AxiosPost(`${Config.dominio}/php/generica_list.php`,
      JSON.stringify({ tabela: 5 })
    )
      .then(async function (response) {
        let _lista = []
        response.data.map(item => _lista.push({ valor: item.valor, descricao: item.descricao }))
        this.setState({ lstStatusOS: _lista })
      }.bind(this))
      .catch(erro => console.log(erro))
  }

  async listarTipoMedicao() {
    await AxiosPost(`${Config.dominio}/php/generica_list.php`,
      JSON.stringify({ tabela: 1 })
    )
      .then(async function (response) {
        let _lista = []
        response.data.map(item => _lista.push({ valor: item.valor, descricao: item.descricao }))
        this.setState({ lstTipoMedicao: _lista })
      }.bind(this))
      .catch(erro => console.log(erro))
  }

  async listarOS() {
    await AxiosPost(`${Config.dominio}/php/os/ordemServico_list.php`,
                    JSON.stringify({ idPonto: this.state.id })
    )
      .then(async (response) => this.setState({ lstOS: response.data }))
      .catch(erro => console.log(erro))
  }

  async materiais() {
    await AxiosPost(`${Config.dominio}/php/materiais_list.php`,
                     JSON.stringify({ dadosPonto: 1 })
                   )
    .then(async function (response) {
      let _lista = []
      response.data.filter(item => item.dadosPonto == 1)
                      .map(item => _lista.push({
                                                value: item.id,
                                                label: item.codigo + " " + item.descricao
                                              })
                          )
      this.setState({
        lstMateriaisRef: response.data,
        lstMateriais: _lista
      })
    }.bind(this))
    .catch(erro => console.log(erro))
  }

  async buscarMateriais(_id) {
    // listar materiais inseridos pelo cadastro
    let lista = []
    await AxiosPost(`${Config.dominio}/php/pontosIluminacao/pontosIluminacao_material_list.php`,
      JSON.stringify({ ponto: this.state.id })
    )
      .then(async response => {
        response.data.sort((a, b) => a.material > b.material ? 1 : -1).map(item =>
          lista.push({
            material: this.state.lstMateriaisRef.find(ii => ii.id == item.id),
            quantidade: item.quantidade_total,
            novo: false,
            remover: false
          })
        )
        this.setState({ lstMaterial: lista })
      }
      )
      .catch(erro => console.log(erro))
  }

  adicionarItens() {
    if (this.state.materialItem) {
      let _lista = this.state.lstMaterial
      _lista.push({
        material: this.state.lstMateriaisRef.find(item => item.id == this.state.materialItem.value),
        quantidade: this.state.materialQuantidade,
        novo: true,
        remover: false
      })
      this.setState({ lstMaterial: _lista })
    } else {
      toast.warning('Informe um material para cadastrar.', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      })
    }
    this.setState({ materialItem: null, materialQuantidade: 1 })
  }

  removerItens(index) {
    let _lista = this.state.lstMaterial
    if (_lista[index].novo) {
      _lista = this.state.lstMaterial.filter((item, ind) => index != ind)
    } else {
      _lista[index].remover = true
    }
    this.setState({ lstMaterial: _lista })
  }

  async CEP(event) {
    let _cep = this.state.cep ? this.state.cep.replace(/\D/g, '') : document.getElementById("cep").value.replace(/\D/g, '')
    if (_cep != "") {
      let validacep = /^[0-9]{8}$/
      if (validacep.test(_cep)) {
        Axios.get(`https://viacep.com.br/ws/${_cep}/json/`)
          .then(async function (response) {
            if (response.status == 200 && response.data != null) {
              if (!response.data.erro) {
                let __cep = response.data
                if (__cep.uf == usuario().municipio.nome.split(' / ')[1] &&
                  __cep.localidade.toUpperCase() == usuario().municipio.nome.split(' / ')[0].toUpperCase()) {
                  this.setState({
                    logradouro: __cep.logradouro,
                    bairro: __cep.bairro,
                  })

                  await Geocode.fromAddress((__cep.logradouro != "" ? __cep.logradouro + ", " : "") +
                    (__cep.bairro != "" ? __cep.bairro + ", " : "") +
                    (__cep.localidade != "" ? __cep.localidade + ", " : "") +
                    __cep.uf + " Brazil")
                    .then((response) => {
                      const { lat, lng } = response.results[0].geometry.location
                      this.setState({ latitude: lat, longitude: lng })
                    })
                    .catch(function (erro) {
                      console.error(erro)
                    }
                    )
                    toast.success('Ponto localizado com sucesso!', {
                      position: "top-right",
                      autoClose: 5000,
                      hideProgressBar: true,
                      closeOnClick: true,
                      pauseOnHover: false,
                      draggable: true,
                      progress: undefined
                      
                    });
                } else {
                  toast.warning('Ponto não localizado no município. Verifique.', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined
                  })
                }
              } else {
                alert("CEP não encontrado.")
              }
            }
            else {
              alert("Erro.");
            }
          }.bind(this))
          .catch(function (erro) {
            console.log(erro);
          })

      }
      else {
        alert("Verifique o cep informado.");
      }
    }
  }

  async buscarLocal(e) {
    await Geocode.fromLatLng(this.state.latitude, this.state.longitude)
      .then((response) => {
        let resultado = Utils.Endereco(response)
        if (resultado.siglaUF == usuario().municipio.nome.split(' / ')[1] ||
          resultado.nomeCidade.toUpperCase() == usuario().municipio.nome.split(' / ')[0].toUpperCase()) {
          this.setState({
            cep: resultado.cep,
            logradouro: (resultado.rua + (resultado.numero ? ', ' + resultado.numero : '')),
            bairro: resultado.bairro
            
          });
          toast.success('Ponto localizado com sucesso!', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined
            
          });
        } else {
          toast.warning('Ponto não localizado no município. Verifique.', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined
            
          });
        }
      })
      .catch(erro => console.log(erro))
  }

  async handleSubmit(event) {
    event.preventDefault()

    let lstMaterialPonto = []
    let lstMaterialPontoQuantidade = []
    let lstMaterialPontoAcao = []

    this.state.lstMaterial.map(item => {
      lstMaterialPonto.push(item.material.id)
      lstMaterialPontoQuantidade.push(item.quantidade)
      item.novo && lstMaterialPontoAcao.push("adicionar")
      !item.novo && item.remover && lstMaterialPontoAcao.push("remover")
      !item.novo && !item.remover && lstMaterialPontoAcao.push("nada")
    })

    let _acao = (this.state.id && this.state.id > 0) ? 'pontosIluminacao_update.php' : 'pontosIluminacao_insert.php'

    await AxiosPost(`${Config.dominio}/php/pontosIluminacao/${_acao}`,
      JSON.stringify({
        id: this.state.id,
        barramento: this.state.barramento,
        tombamento: this.state.tombamento,
        telegestao: this.state.telegestao,
        longitude: this.state.longitude,
        latitude: this.state.latitude,
        cep: this.state.cep,
        logradouro: encodeURI(this.state.logradouro),
        bairro: encodeURI(this.state.bairro),
        larguraVia: this.state.larguraVia,
        tipoPavimento: this.state.tipoPavimento,
        larguraPasseio: this.state.larguraPasseio,
        tipoMedicao: this.state.tipoMedicao,
        conduc: this.state.conduc,
        tipoMaterial: this.state.tipoMaterial,
        altura: this.state.altura,
        lstMaterialPonto: lstMaterialPonto.toString(),
        lstMaterialPontoQuantidade: lstMaterialPontoQuantidade.toString(),
        lstMaterialPontoAcao: lstMaterialPontoAcao.toString()
      })
    )
      .then(async function (response) {
        toast.info('Ponto armazenado com sucesso!', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          onClose: () => ReactDOM.render(<LstPontosIluminacao />, document.getElementById('Content'))
        })

      })
      .catch(function (erro) {
        console.log(erro)
      })
  }

  componentWillMount() {
    this.listarTipoPavimento()
    this.listarTipoMaterial()
    this.listarTipoMedicao()
    this.listarStatusOS()
    this.listarOS()
    this.materiais()
  }

  async componentDidMount() {
    if (this.props.id) {
      await AxiosPost(`${Config.dominio}/php/pontosIluminacao/pontosIluminacao_list.php`,
        JSON.stringify({ id: this.props.id })
      )
        .then(async function (response) {
          if (response.data) {
            let i = response.data[0]
            this.setState({
              id: i.id,
              barramento: i.barramento,
              tombamento: i.tombamento,
              telegestao: i.telegestao,
              latitude: i.latitude,
              longitude: i.longitude,
              cep: i.cep,
              logradouro: i.rua,
              bairro: i.bairro,
              larguraVia: i.largura_via,
              tipoPavimento: i.pavimento,
              larguraPasseio: i.largura_passeio,
              tipoMedicao: i.medicao,
              tipoMaterial: i.tipo_poste,
              altura: i.altura
            })
            this.buscarMateriais(i.id)
          }
        }.bind(this))
        .catch(erro => console.log(erro))

      this.setState({ showModal: this.props.action == 'delete' })
    }
  }

  render() {
    return (
      <>
        <ToastContainer />
        <Modal
          show={this.state.showModal}
          containerStyle={{ background: 'Transparent' }}
          transitionSpeed={400}
        >
          <div className="box box-danger">
            <div className="box-body">
              <div className="row">
                <div className="col-xs-12">
                  <center>
                    <h4>Confirma excluir esse Ponto de Iluminação?</h4>
                  </center>
                </div>
              </div>
            </div>
            <div className="box-footer">
              <center>
                <button
                  id="btnExcluir"
                  onClick={this.confirmaExcluir}
                  className="btn btn-danger btn-flat"
                >
                  Excluir
                </button>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <button
                  onClick={this.cancelaExcluir}
                  className="btn btn-warning btn-flat"
                >
                  Cancelar
                </button>
              </center>
            </div>
          </div>
        </Modal>
        <section className="content-header">
          <h1>
            Ponto de Iluminação
          </h1>
          <ol className="breadcrumb">
            <li>
              <button
                type="button"
                className="btn btn-danger btn-flat"
                onClick={() => ReactDOM.render(<LstPontosIluminacao />, document.getElementById('Content'))}
              >Voltar</button>
              <button className="btn btn-primary" type="button" onClick={e => this.handleSubmit(e)} >Salvar</button>
            </li>
          </ol>
        </section>
        <section className="content">
          <div className="row">
            <div className="col-md-3">
              <div className="box box-primary">
                <div className="box-header with-border">
                  <h3 className="box-title">Identificação</h3>
                </div>
                <div className="box-body box-profile">
                  <div className="form-group">
                    <label htmlFor="barramento">Barramento</label>
                    <div className="input-group input-group-sm">
                      <input
                        type="text"
                        value={this.state.barramento || ""}
                        className="form-control"
                        placeholder=""
                        onChange={event => this.setState({ barramento: event.target.value })}
                      />
                    </div>
                    <label htmlFor="tombamento">Tombamento</label>
                    <div className="input-group input-group-sm">
                      <input
                        type="text"
                        value={this.state.tombamento || ""}
                        className="form-control"
                        placeholder=""
                        onChange={event => this.setState({ tombamento: event.target.value })}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-9">
              <div className="nav-tabs-custom">
                <ul className="nav nav-tabs">
                  <li className="active"><a href="#endereco" data-toggle="tab">Endereço</a></li>
                  <li><a href="#local" data-toggle="tab">Local</a></li>
                  <li><a href="#fotos" data-toggle="tab">Fotos</a></li>
                  <li><a href="#materiais" data-toggle="tab">Materiais</a></li>
                  <li><a href="#telegestao" data-toggle="tab">Telegestão</a></li>
                  <li><a href="#OS" data-toggle="tab">Ordens de Serviço</a></li>
                </ul>
                <div className="tab-content">
                  <div className="active tab-pane" id="endereco">
                    <div className="row">
                      <div className="col-xs-3">
                        <label htmlFor="cep">CEP</label>
                        <div className="input-group input-group-sm">
                          <input
                            type="text"
                            className="form-control"
                            value={this.state.cep || ""}
                            onChange={event => this.setState({ cep: event.target.value })}
                          />
                          <span className="input-group-btn">
                            <button type="button" className="btn btn-info btn-flat" onClick={event => this.CEP(event)}>Consultar</button>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xs-12">
                        <label htmlFor="logradouro">Logradouro</label>
                        <input
                          type="text"
                          className="form-control"
                          value={this.state.logradouro || ""}
                          onChange={event => this.setState({ logradouro: event.target.value })}
                          placeholder=""
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xs-12">
                        <label>Bairro</label>
                        <input
                          type="text"
                          className="form-control"
                          value={this.state.bairro || ""}
                          onChange={event => this.setState({ bairro: event.target.value })}
                          placeholder=""
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xs-3">
                        <div className="form-group">
                          <label>UF</label>
                          <input
                            type="text"
                            disabled="disbl"
                            className="form-control"
                            defaultValue={usuario().municipio.nome.split(' / ')[1]}
                          />
                        </div>
                      </div>
                      <div className="col-xs-9">
                        <div className="form-group">
                          <label>Cidade</label>
                          <input
                            type="text"
                            disabled="disabled"
                            className="form-control"
                            defaultValue={usuario().municipio.nome.split(' / ')[0]}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xs-4">
                        <div className="form-group">
                          <label htmlFor="latitude">Latitude</label>
                          <input
                            type="text"
                            value={this.state.latitude || ""}
                            className="form-control"
                            placeholder=""
                            onChange={event => this.setState({ latitude: event.target.value })}
                          />
                        </div>
                      </div>
                      <div className="col-xs-4">
                        <div className="form-group">
                          <label htmlFor="longitude">Longitude</label>
                          <input
                            type="text"
                            value={this.state.longitude || ""}
                            className="form-control"
                            placeholder=""
                            onChange={event => this.setState({ longitude: event.target.value })}
                          />
                        </div>
                      </div>
                      <div className="col-xs-4">
                        <div className="form-group" style={{ textAlign: 'center' }}>
                          <br />
                          <button type="button" onClick={event => this.buscarLocal(event)} className="btn btn-info btn-flat" >Buscar local</button>
                        </div>
                      </div>
                    </div>
                    <div className="form-group" style={{ height: '280px' }}>
                      <Map
                        google={this.props.google}
                        zoom={16}
                        containerStyle={{ position: 'relative', width: '100%', height: '100%' }}
                        initialCenter={{ lat: this.state.latitude, lng: this.state.longitude }}
                        center={{ lat: this.state.latitude, lng: this.state.longitude }}
                        mapTypeControl={false}
                        fullscreenControl={false}
                        streetViewControl={false}
                        onClick={(t, map, coord) => {
                          const { latLng } = coord
                          this.setState({
                            latitude: latLng.lat().toString(),
                            longitude: latLng.lng().toString()
                          })
                        }
                        }
                      >
                        <Marker
                          position={new window.google.maps.LatLng(this.state.latitude, this.state.longitude)}
                        >
                        </Marker>
                      </Map>
                    </div>
                  </div>
                  <div className="tab-pane" id="local">
                    <h3 className="box-title">Sobre a Via</h3>
                    <div className="row">
                      <div className="col-xs-3">
                        <div className="form-group">
                          <label htmlFor="larguraVia">Largura da via</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="metros"
                            value={this.state.larguraVia || ""}
                            onChange={e => this.setState({ larguraVia: e.target.value })}
                          />
                        </div>
                      </div>
                      <div className="col-xs-3">
                        <div className="form-group">
                          <label htmlFor="larguraPasseio">Largura do passeio</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="metros"
                            value={this.state.larguraPasseio}
                            onChange={e => this.setState({ larguraPasseio: e.target.value })}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xs-12">
                        <div className="form-group">
                          <label>Tipo de pavimento</label>
                          <select
                            className="form-control select2"
                            style={{ width: '100%' }}
                            value={this.state.tipoPavimento}
                            onChange={e => this.setState({ tipoPavimento: e.target.value })}
                          >
                            {
                              this.state.lstTipoPavimento.map(opt => <option key={opt.valor} value={opt.valor}>{opt.descricao}</option>)
                            }
                          </select>
                        </div>
                      </div>
                    </div>

                    <h3 className="box-title">Sobre o Poste</h3>
                    <div className="row">
                      <div className="col-xs-3">
                        <div className="form-group">
                          <label htmlFor="altura">Altura do poste</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="metros"
                            value={this.state.altura}
                            onChange={e => this.setState({ altura: e.target.value })}
                          />
                        </div>
                      </div>
                      <div className="col-xs-5">
                        <div className="form-group">
                          <label>Tipo de material</label>
                          <select
                            className="form-control select2"
                            style={{ width: '100%' }}
                            value={this.state.tipoMaterial}
                            onChange={e => this.setState({ tipoMaterial: e.target.value })}
                          >
                            {
                              this.state.lstTipoMaterial.map(opt => <option key={opt.valor} value={opt.valor}>{opt.descricao}</option>)
                            }
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="row">

                      <div className="col-xs-5">
                        <div className="form-group">
                          <label>Tipo de medição</label>
                          <select
                            className="form-control select2"
                            value={this.state.tipoMedicao}
                            style={{ width: '100%' }}
                            onChange={e => this.setState({ tipoMedicao: e.target.value })}
                          >
                            {
                              this.state.lstTipoMedicao.map(opt => <option key={opt.valor} value={opt.valor}>{opt.descricao}</option>)
                            }
                          </select>
                          {this.state.tipoMedicao == 0 &&
                            <>
                              <label>CONDUC</label>
                              <input
                                type="text"
                                className="form-control"
                                value={this.state.conduc}
                                onChange={event => this.setState({ conduc: event.target.value })}
                              />
                            </>
                          }
                        </div>
                      </div>
                    </div>

                  </div>
                  <div className="tab-pane" id="fotos">
                    <div className="form-horizontal">
                      <div className="box-body">
                        <div className="row margin-bottom">
                          <div className="col-sm-6">
                            <img className="img-responsive" src={`fot.jpg`} alt="Photo1" />
                          </div>
                          <div className="col-sm-6">
                            <div className="row">
                              <div className="col-sm-6">
                                <img className="img-responsive" src={`fot.jpg`} alt="Photo2" />
                                <br />
                                <img className="img-responsive" src={`fot.jpg`} alt="Photo3" />
                              </div>
                              <div className="col-sm-6">
                                <img className="img-responsive" src={`fot.jpg`} alt="Photo4" />
                                <br />
                                <img className="img-responsive" src={`fot.jpg`} alt="Photo5" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="box-footer">
                        <button type="button" className="btn btn-info btn-flat">Editar fotos</button>
                      </div>
                    </div>
                  </div>
                  <div className="tab-pane" id="materiais">
                    <ol className="breadcrumb">
                      <li>
                        <button
                          type="button"
                          className="btn btn-info btn-flat"
                          onClick={() => this.setState({ showNovoItem: !this.state.showNovoItem })}
                        >
                          {this.state.showNovoItem ? "Ocultar inclusão" : "Incluir itens"}
                        </button>
                      </li>
                    </ol>

                    <div className="box box-success" style={{ display: `${this.state.showNovoItem ? "block" : "none"}` }}>
                      <div className="box-header with-border">
                        <h3 className="box-title">Novo Item</h3>
                      </div>
                      <div className="box-body" >
                        <div className="row">
                          <div className="col-xs-8">
                            <div className="form-group">
                              <label htmlFor="Material">Material</label>
                              <Select
                                closeMenuOnSelect={true}
                                components={animatedComponents}
                                options={this.state.lstMateriais}
                                style={{ width: '100%' }}
                                value={this.state.materialItem}
                                onChange={selectedOption => this.setState({ materialItem: selectedOption })}
                              />
                            </div>
                          </div>
                          <div className="col-xs-2">
                            <div className="form-group">
                              <label htmlFor="quantidade">Quantidade</label>
                              <input
                                type="text"
                                style={{ textAlign: "right" }}
                                className="form-control"
                                placeholder="quantidade"
                                value={this.state.materialQuantidade || 1}
                                onChange={event => this.setState({ materialQuantidade: event.target.value })}
                              />
                            </div>
                          </div>
                          <div className="col-xs-2">
                            <div className="form-group">
                              <label htmlFor="botao">&nbsp;</label>
                              <button
                                type="button"
                                onClick={() => this.adicionarItens()}
                                className="btn btn-block btn-primary"
                              >
                                Incluir
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {this.state.lstMaterial.length > 0 &&
                      <div className="box box-success">
                        <div className="box-header with-border">
                          <h3 className="box-title">Material do Ponto</h3>
                        </div>
                        <div className="box-body">
                          <table className="table table-bordered table-striped">
                            <thead>
                              <tr>
                                <th style={{ whiteSpace: "nowrap" }}> Material</th>
                                <th style={{ width: "1px", textAlign: "center", whiteSpace: "nowrap" }}>Qtd</th>
                                <th style={{ width: "1px", textAlign: "center", whiteSpace: "nowrap" }}>&nbsp;</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                this.state.lstMaterial.map((item, index) =>
                                  <tr key={index} style={{ display: `${item.remover ? "none" : "table-row"}` }}>
                                    <td style={{ verticalAlign: "middle" }}>{item.material.descricao}</td>
                                    <td style={{ textAlign: "right", verticalAlign: "middle" }}>{item.quantidade}</td>
                                    <td style={{ verticalAlign: "middle" }}><button type="button" className="btn btn-danger" onClick={() => this.removerItens(index)}><i className="fa fa-trash"></i></button></td>
                                  </tr>
                                )
                              }
                            </tbody>
                            <tfoot>
                              <tr>
                                <th style={{ whiteSpace: "nowrap" }}> Material</th>
                                <th style={{ width: "1px", textAlign: "center", whiteSpace: "nowrap" }}>Qtd</th>
                                <th style={{ width: "1px", textAlign: "center", whiteSpace: "nowrap" }}>&nbsp;</th>
                              </tr>
                            </tfoot>
                          </table>
                        </div>
                        <div className="box-footer">
                        </div>
                      </div>
                    }
                  </div>
                  <div className="tab-pane" id="telegestao">
                    <label htmlFor="telegestao">Telegestão</label>
                    <div className="form-group">
                      <input
                        type="text"
                        value={this.state.telegestao == null ? '' : this.state.telegestao}
                        className="form-control"
                        placeholder=""
                        onChange={event => this.setState({ telegestao: event.target.value })}
                      />
                    </div>
                    <br />
                  </div>
                  <div className="tab-pane" id="OS">
                    {this.state.lstOS && this.state.lstOS.length == 0 &&
                      <h3>Não há registro de ocorrências para este ponto.</h3>
                    }
                    {this.state.lstOS && this.state.lstOS.length > 0 && this.state.lstOS.map((os, index) =>
                      <ul className="timeline timeline-inverse">
                        <li key={index}>
                          <i className="fa fa-bullhorn bg-blue" onClick={() => {
                            let item = document.getElementById(`${os.protocolo}`)
                            item.style.display = (item.style.display == "none") ? "block" : "none"
                          }}></i>
                          <div className="timeline-item">
                            <span className="time"><i className="fa fa-clock-o"></i> {format(new Date(os.datahora_atual), 'dd/MM/yyyy HH:mm')}</span>
                            <h3 className="timeline-header">
                              Protocolo: {os.protocolo}
                            </h3>
                            <div className="timeline-body" id={os.protocolo} style={{ display: "none" }}>
                              Problema notificado: {os.desc_problema}<br />
                              Situação atual: {this.state.lstStatusOS.filter(i => i.valor == os.id_status).map(i => i.descricao)} <br />
                              {os.id_status > 2 && <span>Equipe responsável: {os.nome_equipe}</span>}
                            </div>
                          </div>
                        </li>
                      </ul>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    )
  }
}

export default GoogleApiWrapper(
  (props) => ({
    apiKey: Config.apiKey,
    language: 'pt-BR'
  }
  ))(PontosIluminacao)