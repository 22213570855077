import React from 'react'
import smartCity from '../assets/smart-city-1200px.gif'

export default function mainActions (props) {
 
  return (
      <div id="Content" className="content-wrapper">
        <img src={smartCity}></img>
      </div>
    )
  
}
