import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import Modal from 'simple-react-modal'
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react'
import Geocode from 'react-geocode'
import { usuario, factoryLamp } from '../../../../lib/utils2'
import { AxiosPost } from '../../../../lib/utils3'
import LoadingOverlay from "react-loading-overlay";
import styled, { css } from "styled-components";
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import Callcenter from './index'

import Config from '../../../../config'
import "./index.css"

Geocode.setApiKey(Config.apiKey);

const DarkBackground = styled.div`
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 999; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */

  ${props =>
    props.disappear &&
    css`
      display: block; /* show */
    `}
`;
export class callcenterMapa extends Component {

  state = {
    result: [],
    showModal: false,
    btnCancelar: true,
    btnGerarProtocolo: true,
    btnConcluir: false,
    showDescObservacao: true,
    showModalPontoNaoEncontrado: false,
    enderecoPontoNaoEncontrado: null,
    bairroPontoNaoEncontrado: null,
    referenciaPontoNaoEncontrado: null,
    btnCancelarPNE: true,
    btnGerarProtocoloPNE: true,
    btnConcluirPNE: false,
    showDescObservacaoPontoNaoEncontrado: true,
    showModalPontoNovaInstalacao: false,
    enderecoPontoNovaInstalacao: null,
    bairroPontoNovaInstalacao: null,
    referenciaPontoNovaInstalacao: null,
    btnCancelarNI: true,
    btnGerarProtocoloNI: true,
    btnConcluirNI: false,
    showDescObservacaoPontoNovaInstalacao: true,
    pontoSelecionado: null,
    latitude: usuario().municipio.latitude,
    longitude: usuario().municipio.longitude,
    filtro: 'rua',
    ponto: '',
    tipoProblema: '',
    descricaoProblema: '',
    contribuinte: '',
    telefone: '',
    sms: 0,
    email: '',
    protocolo: '',
    lstProblemas: [],
    idUsuario: usuario().id,
    tipoOs: 0,
    mostrarBtnNovaInstalacao: false,
    disableBtn: false,
  }

  handleSubmit = this.handleSubmit.bind(this)
  handleSubmitPontoNaoEncontrado = this.handleSubmitPontoNaoEncontrado.bind(this)
  handleSubmitNovaInstalacao = this.handleSubmitNovaInstalacao.bind(this)

  async listarProblemas() {
    await AxiosPost(`${Config.dominio}/php/generica_list.php`, JSON.stringify({ tabela: 6 }))
      .then(async (response) => {
        let _lista = []
        response.data.map(item => _lista.push({ descricao: item.descricao, valor: item.valor }))
        this.setState({ lstProblemas: _lista, tipoProblema: _lista[0].valor })
      })
      .catch(erro => console.log(erro))
  }

  async handleSubmitNovaInstalacao(e) {
    e.preventDefault()
    await AxiosPost(`${Config.dominio}/php/os/ordemServicoRegistroNovaInstalacao.php`,
      JSON.stringify({
        logradouro: this.state.enderecoPontoNaoEncontrado,
        bairro: this.state.bairroPontoNaoEncontrado,
        referencia: this.state.referenciaPontoNaoEncontrado,
        tipoProblema: 101, // Nova instalação
        descricaoProblema: this.state.descricaoProblema == undefined ? '' : this.state.descricaoProblema,
        contribuinte: this.state.contribuinte,
        nomeContribuinte: this.state.contribuinte,
        telefoneContribuinte: this.state.telefone,
        emailContribuinte: this.state.email,
        telefone: this.state.telefone.replace(/\D/g, ''),
        sms: this.state.sms,
        email: this.state.email,
        idUsuario: this.state.idUsuario
      })
    )
      .then(async (response) => this.setState({ protocolo: response.data, btnGerarProtocoloNI: false, btnCancelarNI: false, btnConcluirNI: true }))
      .catch(erro => console.log(erro))
  }

  async handleSubmitPontoNaoEncontrado(e) {
    e.preventDefault()
    let idUsuario = usuario().id;
    this.setState({ disableBtn: true })
    await AxiosPost(`${Config.dominio}/php/os/ordemServicoRegistroPNE.php`,
      JSON.stringify({
        logradouro: this.state.enderecoPontoNaoEncontrado,
        bairro: this.state.bairroPontoNaoEncontrado,
        referencia: this.state.referenciaPontoNaoEncontrado,
        tipoProblema: this.state.tipoProblema,
        descricaoProblema: this.state.descricaoProblema == undefined ? '' : this.state.descricaoProblema,
        contribuinte: this.state.contribuinte,
        nomeContribuinte: this.state.contribuinte,
        telefoneContribuinte: this.state.telefone,
        emailContribuinte: this.state.email,
        telefone: this.state.telefone.replace(/\D/g, ''),
        sms: this.state.sms,
        email: this.state.email,
        idUsuario: this.state.idUsuario,
        tipoOs: this.state.tipoOs,
      })
    )
      .then(async (response) => this.setState({ protocolo: response.data, btnGerarProtocoloPNE: false, btnCancelarPNE: false, btnConcluirPNE: true }))
      .then(() => this.toastSuccess("Ordem de serviço gerada com sucesso!"))
      
      .catch(erro => this.toastError("Ops! Erro ao gerar protocolo. Por favor, tente novamente."))
      .catch(erro => this.setState({ disableBtn: false }))
      .finally(() => this.setState({ disableBtn: false }))
  }

  toastSuccess (mensagem) {
    toast.success(mensagem, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    })
  }

  toastError(mensagem) {
    toast.error(mensagem, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    })
  }

  
  async handleSubmit(e) {
    e.preventDefault()
    await AxiosPost(`${Config.dominio}/php/os/ordemServicoRegistro.php`,
      JSON.stringify({
        ponto: this.state.pontoSelecionado.id,
        tipoProblema: this.state.tipoProblema,
        descricaoProblema: this.state.descricaoProblema,
        contribuinte: this.state.contribuinte,
        telefone: this.state.telefone.replace(/\D/g, ''),
        sms: this.state.sms,
        email: this.state.email,
        nomeContribuinte: this.state.contribuinte,
        telefoneContribuinte: this.state.telefone,
        emailContribuinte: this.state.email,
        idUsuario: this.state.idUsuario
      })
    )
      .then(async (response) => this.setState({ protocolo: response.data, btnGerarProtocoloNI: false, btnCancelarNI: false, btnConcluirNI: true }))
      .catch(erro => console.log(erro))
  }


  async buscar(e) {
    e.preventDefault()
    if (document.getElementById("pesquisar").value == "") {
      toast.warning('Filtro nulo. Por favor, informe algum valor.', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined
      });
    } else {
      switch (this.state.filtro) {
        case "rua":
          await AxiosPost(`${Config.dominio}/php/pontosIluminacao/pontosIluminacao_list.php`,
            JSON.stringify({
              filtro_rua: document.getElementById("pesquisar").value
            })
          )
            .then(async (response) => {
              if (response.data.length == 0) {
                toast.warning('Nenhum ponto localizado no município. Verifique.', {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: false,
                  draggable: true,
                  progress: undefined
                });
              } else {
                toast.success('Ponto(s) localizado(s) com sucesso!', {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: false,
                  draggable: true,
                  progress: undefined
                });
              }
              this.setState({ result: response.data })
              let lat = 0
              let lng = 0
              response.data.map(item => {
                lat += parseFloat(item.latitude)
                lng += parseFloat(item.longitude)
              })
              lat = lat / response.data.length
              lng = lng / response.data.length
              if (response.data) {
                this.setState({ longitude: lng, latitude: lat })
              }

            })

            .catch(function (erro) {
              console.log(erro)
            })
          break
        case "barramento":
          await AxiosPost(`${Config.dominio}/php/pontosIluminacao/pontosIluminacao_list.php`,
            JSON.stringify({
              filtro_barramento: document.getElementById("pesquisar").value,
              totalRegistros: 1
            })
          )
            .then(async (response) => {
              if (response.data.length == 0) {
                toast.warning('Nenhum ponto localizado no município. Verifique.', {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: false,
                  draggable: true,
                  progress: undefined
                });
              } else {
                toast.success('Ponto(s) localizado(s) com sucesso!', {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: false,
                  draggable: true,
                  progress: undefined
                });
              }
              this.setState({ result: response.data })
              if (response.data) {
                this.setState({
                  longitude: response.data[0].longitude,
                  latitude: response.data[0].latitude
                })
              }
            })
            .catch(function (erro) {
              console.log(erro)
            })
          break
        case "referencia":
          await Geocode.fromAddress(document.getElementById("pesquisar").value + ", " + usuario().municipio.nome.replace('/', ','))
            .then(async (response) => {
              const { lat, lng } = response.results[0].geometry.location
              await AxiosPost(`${Config.dominio}/php/near_points.php`,
                JSON.stringify({
                  lat: lat,
                  lng: lng,
                  distancia: 5,
                  totalRegistros: 1
                })
              )
                .then(async (response) => {
                  this.setState({
                    latitude: response.data[0].latitude,
                    longitude: response.data[0].longitude
                  })
                  this.Marcadores(response.data[0].latitude, response.data[0].longitude)
                })
                .catch(function (erro) {
                  console.log(erro)
                })
            },
              (error) => {
                console.error(error);
              }
            )

          break
        default:
          break
      }
    }
  }

  async Marcadores(_lat = this.state.latitude, _lng = this.state.longitude) {
    await AxiosPost(`${Config.dominio}/php/near_points.php`,
      JSON.stringify({
        lat: _lat.toString(),
        lng: _lng.toString(),
        distancia: 5,
        totalRegistros: 10
      })
    )
      .then(response => this.setState({ result: response.data }))
      .catch((erro) => console.log(erro))
  }

  async enviarMsgs() {
    if (this.state.sms) {
      // enviar sms -> cadastrar mensagem na tabela sms(destino, mensagem)
      let _mensagem = `A prefeitura de ${usuario().municipio.nome} agradece seu contato.\n
                      Sua solicitação foi confirmada.\n
                      Seu protoloco de atendimento: ${this.state.protocolo}`
      await AxiosPost(`${Config.dominio}/php/sms_insert.php`,
        JSON.stringify({
          mensagem: _mensagem,
          destino: this.state.telefone.replace(/\D/g, '')
        })
      )
        .then(async (response) => toast.success('Mensagem na fila de envio!',
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            onClose: () => this.osPendentes()
          })
        )
        .catch(erro => console.log(erro))
    }

  }

  async componentDidMount() {
    this.listarProblemas()
  }

  render() {
    return (
      <>
        <Modal
          show={this.state.showModal}
          containerStyle={{ background: 'white', width: '1014px', minHeight: '410px' }}
          transitionSpeed={100}
        >
          <section className="content">
            <center><h3>Dados da Ordem de Serviço</h3></center>
            <div className="col-md-6">
              <div className="box box-warning">
                <div className="box-body">
                  <div className="row">
                    <div className="col-xs-12">
                      <h4>Ponto selecionado: {this.state.pontoSelecionado && this.state.pontoSelecionado.title}</h4>
                      <div className="row">
                        <label htmlFor="problemarelatado">Problema relatado</label>
                        <select
                          className="form-control"
                          id="tipoProblema"
                          value={this.state.tipoProblema}
                          onChange={e => this.setState({ tipoProblema: e.target.value, showDescObservacao: e.target.value != 99 })}
                        >
                          {
                            this.state.lstProblemas.map(opt => <option key={opt.valor} value={opt.valor}>{opt.descricao}</option>)
                          }
                        </select>
                      </div>
                      <div className="row">
                        {this.state.showDescObservacao && <label htmlFor="observacao">Observação</label>}
                        <textarea
                          style={{ margin: '0', width: '100%', height: '74px' }}
                          onChange={e => this.setState({ descricaoProblema: e.target.value })}
                        >
                        </textarea>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="box-footer">
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="box box-warning">
                <div className="box-body">
                  <div className="row">
                    <div className="col-xs-12">
                      <label htmlFor="contribuinte">Contribuinte</label>
                      <input
                        type="text"
                        className="form-control"
                        id="contribuinte"
                        defaultValue={this.state.contribuinte}
                        onChange={e => this.setState({ contribuinte: e.target.value })}
                        placeholder="Nome do reclamante"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-12">
                      <label htmlFor="telefone">Telefone</label>
                      <div className="input-group">
                        <span className="input-group-addon">
                          <input
                            type="checkbox"
                            onChange={e => this.setState({ sms: e.target.checked ? 1 : 0 })} />
                        </span>
                        <input
                          type="text"
                          className="form-control"
                          id="telefone"
                          defaultValue={`${this.state.telefone}`}
                          onChange={e => this.setState({ telefone: e.target.value })}
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-12">
                      <label htmlFor="email">Email</label>
                      <input
                        type="text"
                        className="form-control"
                        id="email"
                        defaultValue={`${this.state.email}`}
                        onChange={e => this.setState({ email: e.target.value })}
                        placeholder=""
                      />
                    </div>
                  </div>

                  <div className="row">
                    <br />
                    <center>
                      <button
                        disabled={this.state.protocolo != ''}
                        onClick={e => this.setState({ showModal: false })}
                        className="btn btn-warning btn-flat"
                      >
                        Cancelar
                      </button>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <button
                        disabled={this.state.protocolo != ''}
                        onClick={e => this.handleSubmit(e)}
                        className="btn btn-info btn-flat"
                      >
                        Gerar Protocolo
                      </button>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <button
                        disabled={this.state.protocolo == ''}
                        className="btn btn-info btn-flat"
                        onClick={() => ReactDOM.render(<Callcenter />, document.getElementById('Content'))}
                      >
                        Concluir
                      </button>
                    </center>
                  </div>
                </div>
                <div className="box-footer">
                  <div className="row">
                    <div className="col-xs-12">
                      <label htmlFor="protocolo">PROTOCOLO</label>
                      <div className="input-group input-group-sm">
                        <input
                          type="text"
                          className="form-control"
                          style={{ textAlign: "center", fontWeight: "bold" }}
                          id="protocolo"
                          value={this.state.protocolo}
                          placeholder=""
                          readOnly
                        />
                        <span className="input-group-btn">
                          <button type="button" className="btn btn-info btn-flat" onClick={() => this.enviarMsgs()}>Enviar</button>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Modal>

        <Modal
          show={this.state.showModalPontoNaoEncontrado}
          containerStyle={{ background: 'white', width: '1014px', minHeight: '450px' }}
          transitionSpeed={100}
        >
          <ToastContainer />
          <section className="content">

            <DarkBackground disappear={this.state.disableBtn}>
                <LoadingOverlay
                  active={true}
                  spinner={true}
                  text="Gerando protocolo..."
                >
                </LoadingOverlay>
            </DarkBackground>

            <center><h3>Dados da Ordem de Serviço - Ponto não encontrado</h3></center>
            <div className="col-md-6">
              <div className="box box-warning">
                <div className="box-body">
                  <div className="row">
                    <div className="col-xs-12">
                      <div className="row">
                        <label htmlFor="logradouro">Logradouro</label>
                        <input
                          type="text"
                          className="form-control"
                          defaultValue={this.state.enderecoPontoNaoEncontrado}
                          onChange={e => this.setState({ enderecoPontoNaoEncontrado: e.target.value })}
                          placeholder="Nome da rua"
                          id="logradouro"
                        />
                      </div>
                      <div className="row">
                        <label htmlFor="bairro">Bairro</label>
                        <input
                          type="text"
                          className="form-control"
                          defaultValue={this.state.bairroPontoNaoEncontrado}
                          onChange={e => this.setState({ bairroPontoNaoEncontrado: e.target.value })}
                          placeholder="Nome do bairro"
                        />
                      </div>
                      <div className="row">
                        <label htmlFor="referencia">Referência</label>
                        <input
                          type="text"
                          className="form-control"
                          defaultValue={this.state.referenciaPontoNaoEncontrado}
                          onChange={e => this.setState({ referenciaPontoNaoEncontrado: e.target.value })}
                          placeholder="Referência do local"
                        />
                      </div>
                      <div className="row">
                        <label htmlFor="problemarelatado">Problema relatado</label>
                        <select
                          className="form-control"
                          id="tipoProblema"
                          value={this.state.tipoProblema}
                          onChange={e => this.setState({ tipoProblema: e.target.value, showDescObservacao: e.target.value != 99 })}
                        >
                          {
                            this.state.lstProblemas.map(opt => <option key={opt.valor} value={opt.valor}>{opt.descricao}</option>)
                          }
                        </select>
                      </div>
                      <div className="row">
                        {this.state.showDescObservacao && <label htmlFor="observacao">Observação</label>}
                        <textarea
                          style={{ margin: '0', width: '100%', height: '74px' }}
                          onChange={e => this.setState({ descricaoProblema: e.target.value })}
                        >
                        </textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="box box-warning">
                <div className="box-body">
                  <div className="row">
                    <div className="col-xs-12">
                      <label htmlFor="contribuinte">Contribuinte</label>
                      <input
                        type="text"
                        className="form-control"
                        id="contribuinte"
                        defaultValue={this.state.contribuinte}
                        onChange={e => this.setState({ contribuinte: e.target.value })}
                        placeholder="Nome do reclamante"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-12">
                      <label htmlFor="telefone">Telefone</label>
                      <div className="input-group">
                        <span className="input-group-addon">
                          <input
                            type="checkbox"
                            onChange={e => this.setState({ sms: e.target.checked ? 1 : 0 })} />
                        </span>
                        <input
                          type="text"
                          className="form-control"
                          id="telefone"
                          defaultValue={`${this.state.telefone}`}
                          onChange={e => this.setState({ telefone: e.target.value })}
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-12">
                      <label htmlFor="email">email</label>
                      <input
                        type="text"
                        className="form-control"
                        id="email"
                        defaultValue={`${this.state.email}`}
                        onChange={e => this.setState({ email: e.target.value })}
                        placeholder=""
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-xs-6">
                      <div className='radio'>
                        <label htmlFor='tipoOs'>
                          <input type="radio" name="tipoOs" id='tipoOs' onChange={e => this.setState({ tipoOs: 0 })} defaultChecked />
                          Ornamental
                        </label>
                      </div>
                    </div>
                    <div className="col-xs-6">
                      <div className='radio'>
                        <label htmlFor='tipoOs'>
                          <input type="radio" name="tipoOs" id='tipoOs' onChange={e => this.setState({ tipoOs: 1 })} />
                          Convencional
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <br />
                    <center>
                      <button
                        disabled={!this.state.btnCancelarPNE}
                        onClick={e => this.setState({ showModalPontoNaoEncontrado: false })}
                        className="btn btn-warning btn-flat"
                      >
                        Cancelar
                      </button>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <button
                        disabled={!this.state.btnGerarProtocoloPNE}
                        onClick={e => this.handleSubmitPontoNaoEncontrado(e)}
                        className="btn btn-info btn-flat"
                      >
                        Gerar Protocolo
                      </button>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <button
                        disabled={!this.state.btnConcluirPNE}
                        className="btn btn-info btn-flat"
                        onClick={() => ReactDOM.render(<Callcenter />, document.getElementById('Content'))}
                      >
                        Concluir
                      </button>
                    </center>
                  </div>
                </div>
                <div className="box-footer">
                  <div className="row">
                    <div className="col-xs-12">
                      <label htmlFor="protocolo">PROTOCOLO</label>
                      <div className="input-group input-group-sm">
                        <input
                          type="text"
                          className="form-control"
                          style={{ textAlign: "center", fontWeight: "bold" }}
                          id="protocolo"
                          value={this.state.protocolo}
                          placeholder=""
                          readOnly
                        />
                        <span className="input-group-btn">
                          <button type="button" className="btn btn-info btn-flat" onClick={() => this.enviarMsgs()}>Enviar</button>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Modal>

        <Modal
          show={this.state.showModalPontoNovaInstalacao}
          containerStyle={{ background: 'white', width: '1014px', minHeight: '450px' }}
          transitionSpeed={100}
        >
          <section className="content">
            <center><h3>Dados da Ordem de Serviço - Solicitar nova instalação</h3></center>
            <div className="col-md-6">
              <div className="box box-warning">
                <div className="box-body">
                  <div className="row">
                    <div className="col-xs-12">
                      <div className="row">
                        <label htmlFor="logradouro">Logradouro</label>
                        <input
                          type="text"
                          className="form-control"
                          defaultValue={this.state.enderecoPontoNaoEncontrado}
                          onChange={e => this.setState({ enderecoPontoNaoEncontrado: e.target.value })}
                          placeholder="Nome da rua"
                        />
                      </div>
                      <div className="row">
                        <label htmlFor="bairro">Bairro</label>
                        <input
                          type="text"
                          className="form-control"
                          defaultValue={this.state.bairroPontoNaoEncontrado}
                          onChange={e => this.setState({ bairroPontoNaoEncontrado: e.target.value })}
                          placeholder="Nome do bairro"
                        />
                      </div>
                      <div className="row">
                        <label htmlFor="referencia">Referência</label>
                        <input
                          type="text"
                          className="form-control"
                          defaultValue={this.state.referenciaPontoNaoEncontrado}
                          onChange={e => this.setState({ referenciaPontoNaoEncontrado: e.target.value })}
                          placeholder="Referência do local"
                        />
                      </div>
                      <div className="row">
                        <label htmlFor="observacao">Observação</label>
                        <textarea
                          style={{ margin: '0', width: '100%', height: '74px' }}
                          onChange={e => this.setState({ descricaoProblema: e.target.value })}
                        >
                        </textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="box box-warning">
                <div className="box-body">
                  <div className="row">
                    <div className="col-xs-12">
                      <label htmlFor="contribuinte">Contribuinte</label>
                      <input
                        type="text"
                        className="form-control"
                        id="contribuinte"
                        defaultValue={this.state.contribuinte}
                        onChange={e => this.setState({ contribuinte: e.target.value })}
                        placeholder="Nome do reclamante"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-12">
                      <label htmlFor="telefone">Telefone</label>
                      <div className="input-group">
                        <span className="input-group-addon">
                          <input
                            type="checkbox"
                            onChange={e => this.setState({ sms: e.target.checked ? 1 : 0 })} />
                        </span>
                        <input
                          type="text"
                          className="form-control"
                          id="telefone"
                          defaultValue={`${this.state.telefone}`}
                          onChange={e => this.setState({ telefone: e.target.value })}
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-12">
                      <label htmlFor="email">email</label>
                      <input
                        type="text"
                        className="form-control"
                        id="email"
                        defaultValue={`${this.state.email}`}
                        onChange={e => this.setState({ email: e.target.value })}
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div className="row">
                    <br />
                    <center>
                      <button
                        disabled={!this.state.btnCancelarPNE}
                        onClick={e => this.setState({ showModalPontoNovaInstalacao: false })}
                        className="btn btn-warning btn-flat"
                      >
                        Cancelar
                      </button>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <button
                        disabled={!this.state.btnGerarProtocoloNI}
                        onClick={e => this.handleSubmitNovaInstalacao(e)}
                        className="btn btn-info btn-flat"
                      >
                        Gerar Protocolo
                      </button>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <button
                        disabled={!this.state.btnConcluirPNE}
                        className="btn btn-info btn-flat"
                        onClick={() => ReactDOM.render(<Callcenter />, document.getElementById('Content'))}
                      >
                        Concluir
                      </button>
                    </center>
                  </div>
                </div>
                <div className="box-footer">
                  <div className="row">
                    <div className="col-xs-12">
                      <label htmlFor="protocolo">PROTOCOLO</label>
                      <div className="input-group input-group-sm">
                        <input
                          type="text"
                          className="form-control"
                          style={{ textAlign: "center", fontWeight: "bold" }}
                          id="protocolo"
                          value={this.state.protocolo}
                          placeholder=""
                          readOnly
                        />
                        <span className="input-group-btn">
                          <button type="button" className="btn btn-info btn-flat" onClick={() => this.enviarMsgs()}>Enviar</button>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Modal>

        <section className="content-header">
          <h1>
            Call Center
            <small>Registro de Ordem de Serviço</small>
          </h1>
          <ol className="breadcrumb">
            <li>
              <button
                className="btn btn-primary"
                onClick={() => ReactDOM.render(<Callcenter />, document.getElementById('Content'))} >
                Voltar
              </button>
            </li>
          </ol>
        </section>
        <section className="content">
          <div className="box box-info">
            <div className="box-header">
              <div className="pull-right box-tools">
              </div>
              <div className="row">
                <div className="col-xs-8">
                  <div className="input-group">
                    <div className="input-group-btn">
                      <button type="button" className="btn btn-warning dropdown-toggle" data-toggle="dropdown">Filtro&nbsp;&nbsp;
                        <span className="fa fa-caret-down"></span>
                      </button>
                      <ul className="dropdown-menu">
                        <li><a href="#" onClick={() => {
                          document.getElementById("pesquisar").placeholder = "Nome da Rua"
                          this.setState({ filtro: 'rua' })
                        }
                        }>Nome da Rua</a></li>
                        <li><a href="#" onClick={() => {
                          document.getElementById("pesquisar").placeholder = "Barramento"
                          this.setState({ filtro: 'barramento' })
                        }
                        }>Barramento</a></li>
                        <li><a href="#" onClick={() => {
                          document.getElementById("pesquisar").placeholder = "Ponto de referência"
                          this.setState({ filtro: 'referencia' })
                        }
                        }>Ponto de referência</a></li>
                      </ul>
                    </div>
                    <input type="text" id="pesquisar" placeholder="Nome da Rua" className="form-control" />
                    <span className="input-group-btn">
                      <button type="button" onClick={e => this.buscar(e)} className="btn btn-info btn-flat">Pesquisar</button>
                    </span>
                  </div>
                </div>
                <div className="col-xs-4">
                  <span className="input-group-btn">
                    <button type="button" onClick={() => this.setState({ showModalPontoNaoEncontrado: true })} className="btn btn-warning btn-block btn-flat">Ponto não registrado</button>

                    {/* Quando resolver ativar nova instalação deve-se retirar o btn-block  do botão Ponto não registrado e a verificação do botão solicitar nova instalação */}
                    {this.state.mostrarBtnNovaInstalacao && <button type="button" onClick={() => this.setState({ showModalPontoNovaInstalacao: true })} className="btn btn-info btn-flat">Solicitar Instalação</button>}
                  </span>

                </div>
              </div>
            </div>
          </div>
          <div className="box-body" >
            <div className="form-group" style={{ height: '460px' }}>
              <Map
                google={this.props.google}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={({ map, maps }) => this.renderMarkers(map, maps)}
                zoom={14}
                containerStyle={{ position: 'relative', width: '100%', height: '100%' }}
                initialCenter={{ lat: this.state.latitude, lng: this.state.longitude }}
                center={{ lat: this.state.latitude, lng: this.state.longitude }}
                onClick={(t, map, coord) => {
                  const { latLng } = coord
                  this.setState({ latitude: latLng.lat(), longitude: latLng.lng() })
                  this.Marcadores(latLng.lat(), latLng.lng())
                }}
              >
                {
                  this.state.result && this.state.result.length > 0 && this.state.result.map((ponto, index) =>
                    <Marker
                      key={index}
                      position={new window.google.maps.LatLng(ponto.latitude, ponto.longitude)}
                      title={ponto.barramento}
                      id={ponto.id}
                      icon={factoryLamp(ponto.status_os, ponto.telegestao)}
                      draggable={false}
                      onClick={(item) => this.setState({ showModal: true, pontoSelecionado: item })}
                    />
                  )
                }
              </Map>
            </div>
          </div>
        </section>
      </>
    )
  }


} export default GoogleApiWrapper(
  (props) => ({
    apiKey: Config.apiKey,
    language: 'pt-BR'
  }
  ))(callcenterMapa)
