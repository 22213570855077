import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import ClipLoader from "react-spinners/ClipLoader"
import { addDays, format } from 'date-fns'
import { AxiosPost } from '../../../lib/utils3'

import Config from '../../../config'

export default class descarteEstoque extends Component {

  state = {result: null,
            material: null,
            grupo: null,
            quantidade: "",
            lstMaterial: [],
            lstMaterialShow: [],
            lstGrupo: [],
            lstSaldo: [],
          }

  async buscarMovimentacao() {
    await AxiosPost(`${Config.dominio}/php/estoque/estoque_list.php`,
            JSON.stringify({
              estoque: 2,
            })
    )
    .then(async function (response) {
      let lista = []
      response.data.map((item,index) => lista.push({ 
                                                    descricao: item.observacao,
                                                    data: format(new Date(item.data), 'dd/MM/yyyy HH:mm'),
                                                    protocolo: item.protocolo,
                                                    quantidade: item.quantidade
                                                  }))
      lista.sort((a, b) => a.data < b.data? 1:-1)
      this.setState({result: lista})
    }.bind(this))
  }

  componentDidMount() {
    this.buscarMovimentacao()
  }

  relatorio() {
    if (this.state.result){
      if (this.state.result.length == 0 ) {
        return (
          <center><h2>Não há registros de materiais descartados.</h2></center>
        )
      }
        
      return (
        <table className="table table-bordered table-striped"> 
          <thead>
            <tr>
              <th style={{width: "1px", textAlign: "center", whiteSpace: "nowrap"}}>Data</th>
              <th style={{whiteSpace: "nowrap"}}> Material</th>
              <th style={{width:"1px", textAlign: "right", whiteSpace: "nowrap"}}>Quantidade</th>
              <th style={{width:"1px", textAlign: "right", whiteSpace: "nowrap"}}>Protocolo</th>
            </tr>
          </thead>
          <tbody>
            {this.state.result.map((item, index) =>
              <tr key={index}>
                <td style={{textAlign: "center", whiteSpace: "nowrap"}}>{item.data}</td>
                <td style={{whiteSpace: "nowrap"}}>{item.descricao}</td>
                <td style={{textAlign: "right"}}>{item.quantidade}</td>
                <td style={{textAlign: "right"}}>{item.protocolo}</td>

              </tr>
            )}
          </tbody>
          <tfoot>
          <tr>
              <th style={{width: "1px", textAlign: "center", whiteSpace: "nowrap"}}>Data</th>
              <th style={{whiteSpace: "nowrap"}}> Material</th>
              <th style={{width:"1px", textAlign: "right", whiteSpace: "nowrap"}}>Quantidade</th>
              <th style={{width:"1px", textAlign: "right", whiteSpace: "nowrap"}}>Protocolo</th>
            </tr>
          </tfoot>
        </table>
      )
    }
  }

  render() {
    return(
      <>
        <section className="content-header">
          <h1>
          Descarte
            <small>Materiais descartados nas OSs.</small>
          </h1>
        </section>
        <section className="content">
          <div className="box">
            <div className="box-tools"></div>
          </div>
          <div className="box-body">
            <p />
            <ClipLoader
              css={{display: 'block', margin: '0 auto', borderColor: 'red'}}
              color={"#123abc"}
              loading={!this.state.result}
            />
            {this.relatorio()}
          </div>
        </section>
      </>
    )
  }
}