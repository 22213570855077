import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import ClipLoader from "react-spinners/ClipLoader"
import { addDays, format } from 'date-fns'
import Entrada from './entrada'
import { usuario } from '../../../lib/utils2'
import { AxiosPost } from '../../../lib/utils3'
import Config from '../../../config'

export default class lstEntradaEstoque extends Component {

  state = {
    result: null,
    listaNotas: null,
    material: null,
    pag_atual: 1
  }

  async buscarMovimentacao() {
    await AxiosPost(`${Config.dominio}/php/estoque/estoque_list.php`,
      JSON.stringify({ movimentacao: 'E', estoque: 1 })
    )
      .then(async function (response) {
        let lista = []
        response.data.map(item => lista.push(item))
        let _listaNotas = lista.filter((l, ind) => ind === lista.findIndex(ll => ll.idestoque_fornecedor === l.idestoque_fornecedor))
        console.log(_listaNotas)
        _listaNotas.sort((a, b) => a.dataemissao < b.dataemissao ? 1 : -1)
        let listaNotas = []
        _listaNotas.map(item => listaNotas.push({
          id: item.idestoque_fornecedor,
          documento: item.documento,
          fornecedor: item.fornecedor,
          dataemissao: item.dataemissao != '0000-00-00' ? format(new Date(item.dataemissao), "dd/MM/yyyy") : null,
          total: 0
        }))
        listaNotas.map(nota => nota.total = lista.filter(item => nota.id === item.idestoque_fornecedor).reduce((prev, cur) => prev + (cur.valor * cur.quantidade), 0))
        this.setState({ listaNotas: listaNotas, result: response.data })
      }.bind(this))
  }

  componentDidMount() {
    this.buscarMovimentacao()
  }

  relatorio() {
    if (this.state.listaNotas) {
      if (this.state.listaNotas.length == 0)
        return (<center><h2>Não há registros de entrada no estoque.</h2></center>)

      return (
        <table className="table table-bordered table-striped">
          <thead>
            <tr>
              <th style={{ width: "1px", textAlign: "center", whiteSpace: "nowrap" }}>Nota</th>
              <th style={{ whiteSpace: "nowrap" }}>Fornecedor</th>
              <th style={{ width: "1px", textAlign: "right", whiteSpace: "nowrap" }}>Emissão</th>
              <th style={{ width: "1px", textAlign: "right", whiteSpace: "nowrap" }}>valor Total</th>
              <th style={{ width: "135px", textAlign: "center", whiteSpace: "nowrap" }}>Ações</th>
            </tr>
          </thead>
          <tbody>
            {this.state.listaNotas.map((item, index) =>
              <tr key={index}>
                <td style={{ textAlign: "center", whiteSpace: "nowrap" }}>{item.documento}</td>
                <td style={{ whiteSpace: "nowrap" }}>{item.fornecedor}</td>
                <td style={{ textAlign: "right" }}>{item.dataemissao}</td>
                <td style={{ textAlign: "right", whiteSpace: "nowrap" }}>R$ {Number(item.total).toFixed(2)}</td>
                <td style={{ textAlign: "center" }}>
                  <div className="btn-group">
                    <button type="button" className="btn btn-success" onClick={() => ReactDOM.render(<Entrada id={item.id} action={`view`} />, document.getElementById('Content'))}><i className="fa fa-edit"></i></button>
                    <button type="button" className="btn btn-danger" onClick={() => ReactDOM.render(<Entrada id={item.id} action={`delete`} />, document.getElementById('Content'))}><i className="fa fa-trash"></i></button>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
          <tfoot>
            <tr>
              <th style={{ width: "1px", textAlign: "center", whiteSpace: "nowrap" }}>Nota</th>
              <th style={{ whiteSpace: "nowrap" }}>Fornecedor</th>
              <th style={{ width: "1px", textAlign: "right", whiteSpace: "nowrap" }}>Emissão</th>
              <th style={{ width: "1px", textAlign: "right", whiteSpace: "nowrap" }}>valor Total</th>
              <th style={{ width: "135px", textAlign: "center", whiteSpace: "nowrap" }}>Ações</th>
            </tr>
          </tfoot>
        </table>
      )
    }
  }

  render() {
    return (
      <>
        <section className="content-header">
          <h1>
            Controle de Materiais
            <small>Entrada</small>
          </h1>
          <ol className="breadcrumb">
            <button className="btn btn-block btn-primary" onClick={() => ReactDOM.render(<Entrada />, document.getElementById('Content'))}> Novo lançamento</button>
          </ol>
        </section>
        <section className="content">
          <div className="box">
            <p />
            <div className="col-md-6">
              <div className="input-group">
                <div className="input-group-btn">
                  <button type="button" className="btn btn-warning dropdown-toggle" data-toggle="dropdown">Filtro&nbsp;&nbsp;
                    <span className="fa fa-caret-down"></span></button>
                  <ul className="dropdown-menu">
                    <li><a href="#" onClick={() => document.getElementById("pesquisar").placeholder = "Documento"}>Documento</a></li>
                    <li><a href="#" onClick={() => document.getElementById("pesquisar").placeholder = "Fornecedor"}>Fornecedor</a></li>
                    <li><a href="#" onClick={() => document.getElementById("pesquisar").placeholder = "Data de emissão"}>Data de Emissão</a></li>
                  </ul>
                </div>
                <input type="text" id="pesquisar" className="form-control" placeholder="Documento" />
                <span className="input-group-btn">
                  <button type="button" className="btn btn-info btn-flat" onClick={e => this.pesquisar()}>Pesquisar</button>
                </span>
              </div>
            </div>
            <div className="box-tools">

            </div>
          </div>
          <div className="box-body">
            <p />
            <p />
            <ClipLoader
              css={{ display: 'block', margin: '0 auto', borderColor: 'red' }}
              color={"#123abc"}
              loading={!this.state.listaNotas}
            />
            {this.relatorio()}
          </div>
        </section>
      </>
    )
  }
}